import React, { Component } from 'react';
import "./CustomerServiceHelp.css";
import { Celebration, Close, CloseRounded, ContactSupport, LiveHelp, PartyMode, PhonePaused, Scoreboard as ScoreboardIcon, Support, ThumbUp } from '@mui/icons-material';
import Api from '../../customlib/Api';
import Global from '../../customlib/Global';
import axios from 'axios';
import CsContactListItem from './CsContactListItem';
import { CircularProgress, TextField, Tooltip, Paper, Divider, FormGroup, FormControlLabel, Switch, Tooltip as ToolTip, Badge, IconButton, Menu, MenuItem, LinearProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/styles';
import AntrianCustomerCs from './AntianCustomerCs';
import moment from 'moment';

class CustomerServiceHelp extends Component {
  state = {
    listMycustomer: [],
    page: 0,
    rowsPerPage: 30,
    keyword: "",
    useLimit: false,
    isLoading: true,
    searchMode: false,
    searchInAllContact: false,
    searchInField: "fromName",
    anchorElJustRead: null,
    openMenuJustRead: false,
    showAntrianCustomerCs: false,
    csInQueueCustomerCount: 0
  }

  constructor(){
    super();
    
    // Global.getSocket().on("helpRequestChanged", data => {
    //   console.log("helpRequestChanged : ", data);
    //   if(this.state.searchMode === false){
    //     this.updateCsHelpData(data);
    //     this.getCsHelpList();
    //     // this.props.onUpdateCsHelpState(data);
    //   }
    //   // this.props.onUpdateCsHelpState(data);
      
    // });

    Global.getSocket().on("helpRequestChanged", data => {
      console.log("helpRequestChanged : ", data);

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

      if(this.state.searchMode === false){
        this.updateCsHelpData(data);
        this.getCsHelpList();
        // this.props.onUpdateCsHelpState(data);
      }
      
    });

    Global.getSocket().on("newHelpRequest", data => {
      console.log("newHelpRequest : ", data);

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

      // if(this.state.searchMode === false){
      //   this.updateCsHelpData(data);
      //   this.getCsHelpList();
      //   // this.props.onUpdateCsHelpState(data);
      // }
      this.loadCsInQueueCustomersCount();
      this.props.showSnackBar("Ada customer CS baru :: " + data.contact.fromName);

    });

    Global.getSocket().on("newMessageComing", data => {
      console.log("LOG__NEW_MESSAGE_COMING_LOCAL", data, );
      // console.log("NEW_MESSAGE_COMING_LOCAL",Global.getAppProfile().waNumber, data.to);
      // let isNewContact = data.isNewContact ?? false;

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

      if(Global.getAppProfile().waNumber !== data.to){
        return;
      }

      if(data.contact.csHelpState !== "SOLVING" ){
        if(data.contact.csId > 0){
          this.props.showSnackBar("Pesan baru dari " + data.contact.fromName + " di Antrian Customer CS");
        }
        return;
      }

      let lastMessage = "";
      if(data.message.text !== undefined){
        lastMessage = data.message.text;
      }else{
        if(data.message.caption !== undefined){
          lastMessage = data.message.caption;
        }else if(data.message.type === "STICKER"){
          lastMessage = "Stiker";
        }
      }

      console.log("LOG__CURRENT_CONV_CHECK", this.props.currentConversation);
      if(this.props.currentConversation !== undefined && data.from === this.props.currentConversation.fromNumber){
        // this.getCsHelpList();
          
          
          // this.setState({ mustSendGreeting: false }, () => this.scrollToMyRef());

          this.handleUpdateConversation(this.props.currentConversation, lastMessage);
          // this.props.onResetUnreadCounter(this.props.currentConversation.id, false);
      }else{
          // this.props.onUpdateReadCounter(data);

          let conversations = [...this.state.listMycustomer];
          let findConversation = conversations.find(item => item.fromNumber === data.from);

          if(findConversation){
            let unreadCounter = findConversation.unreadCounter;
            if(unreadCounter && unreadCounter !== ""){
              unreadCounter += 1;
              findConversation.unreadCounter = unreadCounter;
            }else{
              findConversation.unreadCounter = 1;
            }
            findConversation.text = lastMessage;
            findConversation.lastMessageTime = data.contact.lastMessageTime;
            let sortedConversations = this.sortConversations(conversations)
            this.setState({listMycustomer: sortedConversations})
          }

          // this.getCsHelpList();
      }

      // var isMyContact = false;
      // try{
      //   isMyContact = this.props.conversationList.filter((contact) => contact.fromNumber===data.from);
      // }catch(err){

      // }

      // if(this.state.muteSound === true){
      //   return;
      // }

      // if(Global.get().admin === true){
      //   try{
      //     if(isNewContact){
      //       this.state.newContactAudio.play();
      //     }else{
      //       this.state.audio.play();
      //     }
          
      //   }catch(err){
      //     console.log("PLAYERROR", err);
      //   }
      // // }else if(data.assignToUserId === Global.get().user.id){
      // }else if(isMyContact.length > 0 || data.assignToUser === Global.get().user.id){
      //   try{
      //     if(isNewContact){
      //       this.state.newContactAudio.play();
      //     }else{
      //       this.state.audio.play();
      //     }
      //   }catch(err){
      //     console.log("PLAYERROR", err);
      //   }
      // }

    });

    
   
  }

  sortConversations = (conversationLists) => {
    // const conversations = [...this.state.conversations];

    conversationLists.sort((a,b) => b.lastMessageTime-a.lastMessageTime);
    return conversationLists;
    // this.setState({conversations: conversations});

  }

  handleUpdateConversation = (conversation, newMessageText) => {
    // console.log("UPDATECONVERSATION", conversation, newMessageText);
    const conversations = [...this.state.listMycustomer]; //... = create clone
    const clonedConversation = conversations.find(item => item.from === conversation.from);

    if(clonedConversation){
      clonedConversation.text = newMessageText;
      // console.log("UPDATECONVERSATION", conversation.index, conversations[conversation.index].photo);
      this.setState({ listMycustomer: conversations });
    }
  };

  showSnackBar = (message) => {
    this.props.showSnackBar(message);
  }

  handleClosePopupMenuJustRead = () => {
    this.setState({openMenuJustRead: false, anchorElJustRead: null}, () => {
      // this.setState({anchorEl: null});
    });
  };

  handleJustReadMenuClicked = (event) => {
    this.setState({anchorElJustRead: event.currentTarget, openMenuJustRead: true}, () => {
      this.props.onUpdateJustRead();
    });
  };

  handleKeyword = (event) => {
    this.setState({ keyword: event.target.value });
  };

  onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      this.getCsHelpList();
    }
  };

  updateCsHelpData = (data) => {
    const csHelpList = [...this.state.listMycustomer];
    let contact = csHelpList.filter((conv) => conv.contactId===data.contactId);

    if(contact.length > 0){
      contact[0].csHelpState = data.helpState;
      if(data.csNote !== undefined){
        contact[0].csNote = data.csNote;
      }

      if(data.label !== undefined){
        contact[0].contactLabel = data.label;
      }

      this.setState({listMycustomer: csHelpList});
    }else{
      this.getCsHelpList();
    }

  }

  getCsHelpList = () => {

    // if(this.state.keyword.length <= 0){
    //   this.setState({listMycustomer: []});
    //   return;
    // }

    this.setState({isLoading: true}, () => {
      var userData = Global.get();
      const params = {
          userId: userData.user.id,
          appProfileId: Global.getAppProfile()._id
      }

      if(this.state.searchMode === true){
        params.query = this.state.keyword;
        params.searchInAllContact = this.state.searchInAllContact;
        params.searchInField = this.state.searchInField
      }

      console.log("PARAMS", params);

      axios
      .post(Api.csActiveCustomers(), params, Api.getRequestHeader())
      .then((response) => {
          console.log("MyCustomerToday : ", response.data.data);
          var customers = response.data.data;
          var contacts = [];
          customers.forEach(customer => {
              let newData =  {
                photo: "https://picsum.photos/seed/picsum/200",
                fromName: `${customer.fromName}`,
                text: customer.lastMessage,
                fromNumber: customer.from,
                contactId: customer.id,
                toNumber: customer.to,
                toName: customer.toName,
                selected: false,
                unreadCounter: customer.unreadCounter,
                assignToUserName: customer.assignToUserName,
                csHelpState: customer.csHelpState,
                showButton: true,
                csNote: customer.csNote,
                lastWaCsLog: customer.lastWaCsLog,
                contactLabel: customer.label,
                firstContactAssignedToName: customer.firstContactAssignedToName,
                lastMessageTime: customer.lastMessageTime,
                csCreatedAt: customer.csCreatedAt
                };
              contacts.push(newData);
          });

          let sortedConversations = this.sortConversations(contacts);

          this.setState({listMycustomer: sortedConversations, isLoading: false});
      })
      .catch((error) => {
        this.setState({isLoading: false});
        console.error("There was an error!", userData.user.id, error);
      });
    });

  };

  loadCsInQueueCustomersCount = () => {

    var userData = Global.get();
    const params = {
        userId: userData.user.id,
        appProfileId: Global.getAppProfile()._id
    }

    console.log("PARAMS", params);

    axios
    .post(Api.csInQueueCustomersCount(), params, Api.getRequestHeader())
    .then((response) => {
        console.log("MyCustomerToday : ", response.data.data);

        let count = response.data.data.csInQueueCustomerCount;
      
        this.setState({csInQueueCustomerCount: count});
    })
    .catch((error) => {
      this.setState({isLoading: false});
      console.error("There was an error!", userData.user.id, error);
    });

  };

  getFieldClassName = (fieldName) => {
    let className = "badge";
    if(this.state.searchInField === fieldName){
      className += " badge-primary";
    }else{
      className += " badge-secondary";
    }

    return className;
  }

  handleFieldSelectionChanged = (fieldName) => {
      this.setState({searchInField: fieldName}, () => {
        this.getCsHelpList();
      });
  }

  handleOpenChat = (conversation) => {
    let conversations = [...this.state.listMycustomer];
    let findConversation = conversations.find(item => item.fromNumber === conversation.fromNumber);
    if(findConversation){
      findConversation.unreadCounter = 0;
    }

    this.setState({listMycustomer: conversations}, () => {
      this.props.onSelect(conversation);
    });
  }

  getUsersBySlugRole = () => {

    // if(this.state.keyword.length <= 0){
    //   this.setState({listMycustomer: []});
    //   return;
    // }

    this.setState({isLoading: true}, () => {
      var userData = Global.get();
      const params = {
          slugRole: "nightshift"
      }
      console.log("PARAMS", params);

      axios
      .post(Api.usersBySlugRole(), params, Api.getRequestHeader())
      .then((response) => {
          console.log("Users By Slug Role : ", response.data);
          // var customers = response.data.data;
          // var contacts = [];
          // customers.forEach(customer => {
          //     let newData =  {
          //       photo: "https://picsum.photos/seed/picsum/200",
          //       fromName: `${customer.fromName}`,
          //       text: customer.lastMessage,
          //       fromNumber: customer.from,
          //       contactId: customer.id,
          //       toNumber: customer.to,
          //       toName: customer.toName,
          //       selected: false,
          //       unreadCounter: customer.unreadCounter,
          //       assignToUserName: customer.assignToUserName,
          //       csHelpState: customer.csHelpState,
          //       showButton: true,
          //       csNote: customer.csNote,
          //       lastWaCsLog: customer.lastWaCsLog,
          //       contactLabel: customer.label,
          //       firstContactAssignedToName: customer.firstContactAssignedToName
          //       };
          //     contacts.push(newData);
          // });

          // this.setState({listMycustomer: contacts, isLoading: false});
      })
      .catch((error) => {
        this.setState({isLoading: false});
        console.error("There was an error!", userData.user.id, error);
      });
    });

  };

  handleUpdateNote = (conversation, note) => {
    const conversations = [...this.state.listMycustomer];
    let contact = conversations.filter((conv) => conv.contactId===conversation.contactId);

    if(contact.length > 0){
      console.log("CONTACTFOND : ", contact);
      contact[0].csNote = note;

      this.setState({isSavingNote: false, listMycustomer: conversations, isEditingNote: false});
    }
  }

  handleSwitchLeads = () => {
    let mode = false;
    if(this.state.searchMode === true){
      mode = false;
    }else{
      mode = true;
    }

    this.setState({searchMode: mode, keyword: ""}, () => {
      this.getCsHelpList();
    });

  }

  onCustomerStarted = (contact) => {

    let milis = moment().tz("utc").format("x");

    console.log("STARTED", contact, milis);
    const customers = [...this.state.listMycustomer];
    contact.lastMessageTime = milis;
    customers.push(contact);
    this.setState({listMycustomer: customers}, () => {
      this.sortConversations(this.state.listMycustomer);
      // this.toggleShowAntrianCustomerCs();
    })
  }

  handleSwitchSearchContext = () => {
    let mode = false;
    if(this.state.searchInAllContact === true){
      mode = false;
    }else{
      mode = true;
    }

    this.setState({searchInAllContact: mode, keyword: this.state.keyword}, () => {
      this.getCsHelpList();
    });

  }

  toggleShowAntrianCustomerCs = () => {
    this.setState({showAntrianCustomerCs: !this.state.showAntrianCustomerCs});
  }

  componentDidMount() {
    this.getCsHelpList();
    this.getUsersBySlugRole();

    setTimeout(() => {
      this.props.onUpdateJustRead();
      this.loadCsInQueueCustomersCount();
    }, 3000)
  }

  render() { 
    return ( 
      <>
        <Paper elevation={0} style={{marginTop: 10, marginLeft: 5, marginRight: 5, marginBottom: 10, zIndex: 0}}>
          <div style={{position: "static", zIndex: -10000}}>
            <div className="row">
              <div className="col-7">
                <div className="container">
                  <strong>CS Job Lists</strong>
                </div>
              </div>
              <div className="col-5" style={{paddingTop: "8px"}}>
                <ToolTip title="Antrian Customer CS">
                  {/* <div onClick={this.props.onJustReadMenuClicked} className="btn btn-warning btn-sm"> */}
                    <IconButton size='small' onClick={this.toggleShowAntrianCustomerCs} >
                      <Badge badgeContent={this.state.csInQueueCustomerCount} color="secondary">
                        <LiveHelp fontSize='10px' color="action" />
                      </Badge>
                    </IconButton>
                    
                  {/* </div> */}
                </ToolTip>
                <ToolTip title="Inbox sudah dibaca tapi belum di respon">
                  {/* <div onClick={this.props.onJustReadMenuClicked} className="btn btn-warning btn-sm"> */}
                    <IconButton size='small' onClick={this.handleJustReadMenuClicked} >
                      <Badge badgeContent={this.props.justReadMessages.length} color="secondary">
                        <PhonePaused fontSize='10px' color="action" />
                      </Badge>
                    </IconButton>
                    
                  {/* </div> */}
                </ToolTip>
                <Menu
                  id="popup-slowresponse"
                  anchorEl={this.state.anchorElJustRead}
                  open={this.state.openMenuJustRead}
                  onClose={this.handleClosePopupMenuJustRead}
                  transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {
                    this.props.justReadMessages.map((slow) => {
                      return (
                        <MenuItem
                          key="dashboardJustReadReportMenu"
                          onClick={() => this.props.onSelect(slow.contact)}
                        >
                          
                              <div className="" style={{width: "300px"}}>
                                {slow.fromName}<br />
                                <div className="row">
                                  <div className="col-6">
                                      <div className="badge badge-pill badge-secondary">
                                          {slow.assignToUserName}
                                      </div>&nbsp;
                                      <div className="badge badge-pill badge-secondary">
                                          {Global.maskPhoneNumber(slow.from)}
                                      </div>&nbsp;
                                  </div>
                                  <div className="col-6">
                                      <div className={Global.getLabelClasses(slow.label)}>
                                        {slow.label}
                                      </div>&nbsp;
                                      {
                                        slow.isMessageReplied === true ? 
                                          (
                                            <div className="badge badge-pill badge-success">
                                              {/* <Chat className="text-success" /> */} sudah direspon
                                            </div>
                                          ) : 
                                          (
                                            <div className="badge badge-pill badge-danger">
                                              {/* <Warning className="text-danger" /> */}
                                              {slow.delta} 
                                            </div>
                                          )
                                      }
                                  </div>
                                </div>
                                
                                
                                {/* {
                                  slow.isMessageReplied === true ? (<Chat className="text-success" />) : (<Warning className="text-danger" />)
                                } */}
                              </div>
                          
                        </MenuItem>

                    )
                  })
                }

                {
                  this.props.justReadMessages.length <= 0 && <MenuItem onClick={this.handleClosePopupMenuJustRead}><Celebration color='primary' />&nbsp; Semua inbox sudah di response&nbsp; <ThumbUp color='error' /></MenuItem>
                }
                </Menu>
                &nbsp;&nbsp;&nbsp;
                {
                  !Global.isCustomerService() ? 
                  (
                    <Tooltip title="Tutup">
                      <IconButton size='small' onClick={this.props.onCloseCsHelp}>
                        <CloseRounded color='error'/>
                      </IconButton>
                      
                    </Tooltip>
                  ) : 
                  (<></>)
                }

              </div>
            </div>
            <Divider />

            {
            this.props.showButtonCheckPoint ? 
            (
              <div className="col-12" style={{marginBottom: 10, marginTop: 20}}>
                <ToolTip title="Check Point">
                    <div className="btn btn-warning btn-block" onClick={this.props.assignCheckPoint}>
                      <ScoreboardIcon className="text-danger" /> 
                      &nbsp;
                      <span style={{color: "white", fontWeight: "bold"}}>Check in sekarang juga !!!</span>
                    </div>
                </ToolTip>
              </div>
            ) : 
            Global.get().checkPoint.isNightShift === true && Global.get().checkPoint.rules.length > 0 ? 
            (
              <div className="col-12" style={{marginBottom: 10, marginTop: 10}}>
                <Tooltip title="Check Point">
                    <div className="btn btn-success btn-block">
                      <ScoreboardIcon className="text-light" /> 
                      &nbsp;
                      <span style={{color: "white", fontWeight: "bold"}}>Checkpoint Aktif</span>
                    </div>
                </Tooltip>
              </div>
            ) : 
            (<></>)
          }

          <div className="text-center">
            <FormControlLabel
              label= {this.state.searchMode === true ? "Search Mode (ON), Realtime Off" : "Search Mode (OFF), Realtime On"}
              control={
                <Switch
                  checked={this.state.searchMode}
                  onChange={this.handleSwitchLeads}
                  name="switchLeads"
                  // color="primary"
                  size='small'
                />
              }
            />
          </div> 
        </div>
          
          
          

          {
            this.state.searchMode === true ? 
            (
              <div className="conversation-search">
                <TextField
                  onChange={this.handleKeyword}
                  label="Cari nama / no wa / label"
                  type="text"
                  className="form-control"
                  id="searchCustomer"
                  variant="outlined"
                  onKeyDown={this.onKeyDownHandler}
                  onBlur={() => {
                      
                  }}
                  size='small'
                />
                {/* <br /> */}
                <div className="container-fluid">
                    <small>Field Pencarian : </small>
                    {/* <div> */}
                        <div className={this.getFieldClassName("fromName")} onClick={() => this.handleFieldSelectionChanged("fromName")} style={{cursor: "pointer"}}>Nama</div>&nbsp;
                        <div className={this.getFieldClassName("from")} onClick={() => this.handleFieldSelectionChanged("from")} style={{cursor: "pointer"}}>Nomor</div>&nbsp;
                        <div className={this.getFieldClassName("label")} onClick={() => this.handleFieldSelectionChanged("label")} style={{cursor: "pointer"}}>Label</div>
                    {/* </div> */}
                </div>
                {/* <br /> */}
                {
                  Global.get().admin && !Global.isCustomerService() ? 
                  (
                    <div className="text-right">
                      <FormControlLabel
                        label= "Cari di semua kontak"
                        control={
                          <Switch
                            checked={this.state.searchInAllContact}
                            onChange={this.handleSwitchSearchContext}
                            name="switchSearchContext"
                            color="primary"
                          />
                        }
                        
                      />
                    </div>
                  ) : 
                  (<></>) 
                }
                {/* <br />
                  */}
              </div>
            ) : 
            (
              <></>
            )
          }
          
          {/* <div className="conversation-search">
            <TextField
              onChange={this.handleKeyword}
              label="Cari nama / no wa / sales"
              type="text"
              className="form-control"
              id="namacustomer"
              variant="outlined"
              onKeyDown={this.onKeyDownHandler}
              onBlur={() => {
                  
              }}
          />
          <br />
          </div> */}

          {
            this.state.isLoading === true ? 
            (<LinearProgress />) : (<></>)
          }
          
        </Paper>
        {/* <div style={{overflowY: 'scroll', marginBottom: 10, maxHeight: 500, position: "static"}}> */}
        <div style={{marginBottom: 10, position: "static"}}>
          {
            this.state.listMycustomer?.map((conversation) => {
              
              return (
                <CsContactListItem
                  // onSelect={conversation.csHelpState === "OPEN" ? () => {this.props.showSnackBar('Klik tombol "START" untuk memulai conversation dengan ' + conversation.fromName)} : this.props.onSelect}
                  key={conversation.fromNumber}
                  data={conversation}
                  onUpdateNote={this.handleUpdateNote}
                  reloadCsHelpList={this.getCsHelpList}
                  onClose={this.props.onClose}
                  onOpenChat={this.handleOpenChat}
                  // onBtnEditNoteClick={this.handleToggleShowNoteInput}
                  // onUpdateHelpState={this.handleSetHelpState}
                  // isSavingNote={this.state.isSavingNote}
                  // onSaveNote={this.handleSaveNote}
                  // isEditingNote={this.state.isEditingNote}
                  // savingNoteContactId={this.state.savingNoteContactId}
                />
              )
                    
              })
          }
        </div>
        <Dialog 
          open={this.state.showAntrianCustomerCs}
          maxWidth={"sm"}
          >
            <DialogTitle>
              <div className="row">
                <div className="col-10" style={{paddingTop: "5px"}}>
                  Antrian Customer CS
                </div>
                <div className="col-2 text-right">
                  <IconButton onClick={this.toggleShowAntrianCustomerCs}><Close /></IconButton>
                </div>
              </div>
            </DialogTitle>
          <DialogContent>
            <AntrianCustomerCs onClose={this.toggleShowAntrianCustomerCs} onCustomerStarted={this.onCustomerStarted} />
            {/* <div style={{marginBottom: 10, position: "static"}}>
            {
              this.state.listMycustomer.map((conversation) => {
                
                return (
                  <CsContactListItem
                    onSelect={conversation.csHelpState === "OPEN" ? () => {this.props.showSnackBar('Klik tombol "START" untuk memulai conversation dengan ' + conversation.fromName)} : this.props.onSelect}
                    key={conversation.fromNumber}
                    data={conversation}
                    onUpdateNote={this.handleUpdateNote}
                    reloadCsHelpList={this.getCsHelpList}
                    // onBtnEditNoteClick={this.handleToggleShowNoteInput}
                    // onUpdateHelpState={this.handleSetHelpState}
                    // isSavingNote={this.state.isSavingNote}
                    // onSaveNote={this.handleSaveNote}
                    // isEditingNote={this.state.isEditingNote}
                    // savingNoteContactId={this.state.savingNoteContactId}
                  />
                )
                      
                })
            } 
          </div> */}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </>
    );
  }
}
 
export default CustomerServiceHelp;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));