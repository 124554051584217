import { Add, BrowseGallery, Close, CloudUpload, Delete, DeleteForeverOutlined, FileUpload, Folder, Info, InfoTwoTone, Search, Upload } from '@mui/icons-material';
import { Autocomplete, Avatar, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, Select, Snackbar, Switch, TextField, Tooltip } from '@mui/material';
import React, { Component } from 'react';
import "./CreateOrder.css";
import axios from 'axios';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import Dropzone from 'react-dropzone';
import base64 from "base-64";

class CustomerProfile extends Component {
    state = { 
        dataOrder: {
            customerId: 0,
            customerEmail: "",
            customerPhone: "",
            customerName: "",
            memberStatus: "",
            customerType: "",
            customerGender: "",
            jenisPertanyaan: "",
            levelCustomer: "",
            jawabanCustomer: "",
            kesimpulanJawabanCustomer: ""
        },
        listCountry: [],
        listProvince: [],
        listCity: [],
        keyword: "",
        page: 1,
        customLogoFile: null,
        customLogoBase64: null,
        customDesignFile: null,
        customDesignBase64: null,
        extractedDataOrder: null,
        showSnackbar: false,
        snackbarMessage: "",
        isLoadingCustomerInfo: false,
        isDialogNotFoundOpen: false,
        isSubmitting: false,
        showDialogSubmitSuccess: false
    } 

    toggleDialogNotFound = () => {
        let isDialogOpen = this.state.isDialogNotFoundOpen;
        if(isDialogOpen){
            this.props.onCloseDrawer();
        }
        this.setState({isDialogNotFoundOpen: !isDialogOpen});
    }

    toggleDialogSubmitSuccess = () => {
        let isDialogOpen = this.state.showDialogSubmitSuccess;
        if(isDialogOpen){
            this.props.onCloseDrawer();
        }
        this.setState({showDialogSubmitSuccess: !isDialogOpen});
    }

    loadCustomerInfo = () => {
        this.setState({isLoadingCustomerInfo: true}, () => {
            axios
                .get(Api.lavenderGetCustomerInfo(this.state.dataOrder.customerEmail, this.state.dataOrder.customerPhone), Api.getRequestHeaderNoAuth())
                // .get(Api.lavenderGetCustomerInfo("kekeke", "wkwkw"), Api.getRequestHeaderNoAuth())
                .then((response) => {
                    console.log("CUSTOMER_INFO : ", response.data);
                    let data = response.data;

                    if(data){
                        let dataOrder = {...this.state.dataOrder};
                        dataOrder.customerEmail = data.email;
                        dataOrder.customerPhone = data.phone;
                        dataOrder.customerName = data.name;
                        dataOrder.memberStatus = data.is_member;
                        dataOrder.customerType = data.type;
                        dataOrder.customerGender = data.gender;
                        dataOrder.customerId = parseInt(data.id);
                        this.setState({dataOrder: dataOrder, isLoadingCustomerInfo: false});
                    }else{
                        // this.handleShowSnackbar("Customer dengan \nemail: " + this.state.dataOrder.customerEmail + " dan \nphone: " + this.state.dataOrder.customerPhone + "\n tidak ditemukan");
                        this.setState({isLoadingCustomerInfo: false});
                        this.toggleDialogNotFound();
                    }

                    // this.setState({listProduct: data, isLoadingSearch: false});
                })
                .catch((error) => {
                    this.setState({isLoadingCustomerInfo: false});
                    console.error("There was an error!", error);
                });
        })
        
    };


    handleProceed = () => {

        let customerEmail = this.state.dataOrder.customerEmail;
        let customerPhone = this.state.dataOrder.customerPhone;
        let customerName = this.state.dataOrder.customerName;
        let customerGender = this.state.dataOrder.customerGender;
        let jenisPertanyaan = this.state.dataOrder.jenisPertanyaan;
        let kesimpulanJawabanCustomer = this.state.dataOrder.kesimpulanJawabanCustomer;


        if(customerEmail?.trim().length <= 0){
            this.handleShowSnackbar("Customer Email belum diisi");
            return;
        }

        if(!Global.isEmailValid(customerEmail)){
            this.handleShowSnackbar("Email tidak valid");
            return;
        }

        if(customerPhone?.trim().length <= 0){
            this.handleShowSnackbar("Customer Phone belum diisi");
            return;
        }

        if(!Global.isPhoneValid(customerPhone)){
            this.handleShowSnackbar("Customer phone tidak valid");
            return;
        }

        if(customerName?.trim().length <= 0){
            this.handleShowSnackbar("Customer Name belum diisi");
            return;
        }

        if(customerGender?.trim().length <= 0){
            this.handleShowSnackbar("Customer Gender belum diisi");
            return;
        }

        if(jenisPertanyaan?.trim().length <= 0){
            this.handleShowSnackbar("Jenis pertanyaan belum dipilih");
            return;
        }

        if(kesimpulanJawabanCustomer?.trim().length <= 0){
            this.handleShowSnackbar("Kesimpulan jawaban customer belum diisi");
            return;
        }

        this.handleOpenLavender();

    }

    handleShowSnackbar = (message) => {
        this.setState({showSnackbar: true, snackbarMessage: message});
    }

    handleOpenLavender = () => {        

        this.setState({isSubmitting: true}, () => {
            
            let payload = {
                customer_id : this.state.dataOrder.customerId,
                question : this.state.dataOrder.jenisPertanyaan,
                level : this.state.dataOrder.levelCustomer,
                answer : this.state.dataOrder.jawabanCustomer,
                conclusion : this.state.dataOrder.kesimpulanJawabanCustomer
              }

            axios
                .post(Api.lavenderSubmitCustomerProfile(), payload, Api.getRequestHeaderNoAuth())
                .then((response) => {
                    
                    let data = response.data;

                    if(data && data.success === true){
                        // this.handleShowSnackbar("Customer profile berhasil disubmit, akan segera direview");
                        this.setState({isSubmitting: false, showDialogSubmitSuccess: true});
                        // this.props.onCloseDrawer();
                    }else{
                        this.handleShowSnackbar(data.msg);
                        this.setState({isSubmitting: false});
                    }
                    
                })
                .catch((error) => {
                    this.setState({isSubmitting: false});
                });
        })

        // console.log("PAYLOAD", payload);
        // let payloadBase64 = base64.encode(JSON.stringify(payload));
        // console.log("PAYLOAD", payloadBase64);

        // var url = Api.getLavenderUrl() + "https://lavender.prestisa.id/api/service/0efade19437fb381e3526380bc53659c/customer-profile/create" + payloadBase64;
        // var win = window.open(url, '_blank');
        // win.focus();

    }

    LEVEL_CUSTOMER = [
        "Customer",
        "Teman",
        "Sahabat",
        "Keluarga"
    ]

    JENIS_PERTANYAAN = [
        { userType: "SALES", question: "Nama", parent: "" },
        { userType: "SALES", question: "Jenis kelamin", parent: "" },
        { userType: "SALES", question: "Email", parent: "" },
        { userType: "SALES", question: "No telepon", parent: "" },
        { userType: "SALES", question: "Alamat kantor", parent: "" },
        { userType: "SALES", question: "Kota alamat kantor", parent: "" },

        { userType: "CS", question: "Masalah yang sedang dihadapi", parent: "" },
        { userType: "CS", question: "Tanggapan terhadap produk prestisa", parent: "" },
        { userType: "CS", question: "Saran perbaikan", parent: "" },

        { userType: "TELEMARKETING", question: "Respon terhadap promo", parent: "Customer" },
        { userType: "TELEMARKETING", question: "Loyalitas pembelian", parent: "Customer" },
        { userType: "TELEMARKETING", question: "Produk yang sering dicari", parent: "Customer" },
        { userType: "TELEMARKETING", question: "Faktor pembelian", parent: "Customer" },

        { userType: "TELEMARKETING", question: "Alamat rumah (personal)", parent: "Teman" },
        { userType: "TELEMARKETING", question: "Kota alamat rumah (personal)", parent: "Teman" },
        { userType: "TELEMARKETING", question: "Pendidikan terakhir", parent: "Teman" },
        { userType: "TELEMARKETING", question: "Jumlah anak", parent: "Teman" },
        { userType: "TELEMARKETING", question: "Anak sekolah dimana", parent: "Teman" },
        { userType: "TELEMARKETING", question: "Anak kelas berapa", parent: "Teman" },
        { userType: "TELEMARKETING", question: "Jenis kelamin anak", parent: "Teman" },
        { userType: "TELEMARKETING", question: "Karakteristik customer", parent: "Teman" },
        { userType: "TELEMARKETING", question: "Keyakinan customer", parent: "Teman" },
        { userType: "TELEMARKETING", question: "Sosial media", parent: "Teman" },
        
        { userType: "TELEMARKETING", question: "Pekerjaan (divisi)", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Status pernikahan", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Hobi", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Tanggal lahir", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Jam kantor", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Jam kegiatan", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Pembelian tiket kegiatan", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Budget hobi", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Notes hobi", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Suku", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Pekerjaan pasangan", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Kota kantor pasangan", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Notes pernikahan", parent: "Sahabat" },
        { userType: "TELEMARKETING", question: "Brand yang dipakai", parent: "Sahabat" },

        { userType: "TELEMARKETING", question: "Notes ayah customer", parent: "Keluarga" },
        { userType: "TELEMARKETING", question: "Notes ibu customer", parent: "Keluarga" },
        { userType: "TELEMARKETING", question: "Notes saudara customer", parent: "Keluarga" },
        { userType: "TELEMARKETING", question: "Hobi pasangan", parent: "Keluarga" },
        { userType: "TELEMARKETING", question: "Hobi anak", parent: "Keluarga" }
        // {
        //     userType: "SALES",
        //     questionsType: [
                
        //     ]
        // },
        // {
        //     userType: "CS",
        //     questionsType: [
                
        //     ]
        // },
        // {
        //     userType: "TELEMARKETING",
        //     questionsType: [
        //         ,
        //     ]
        // }
    ];

    getJenisPertanyaan = () => {
        if(Global.isTeleCutomer()){
            return this.JENIS_PERTANYAAN.filter(item => item.userType === "TELEMARKETING");
        }else if(Global.isCustomerService()){
            return this.JENIS_PERTANYAAN.filter(item => item.userType === "CS");
        }else if(Global.isSales()){
            return this.JENIS_PERTANYAAN.filter(item => item.userType === "SALES");
        }else{
            return this.JENIS_PERTANYAAN;
        }
    }

    CUSTOMER_TYPES = [
        {
            id: 0,
            name: "Personal"
        },
        {
            id: 1,
            name: "Corporation"
        }
    ];

    MEMBER_STATUS = [
        {
            id: 0,
            name: "Bukan Member"
        },
        {
            id: 1,
            name: "Member"
        }
    ];

    GENDER = [
        {
            id: "Female",
            name: "Female"
        },
        {
            id: "Male",
            name: "Male"
        }
    ];

    handleCustomerEmailChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.customerEmail = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleCustomerPhoneChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.customerPhone = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleCustomerNameChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.customerName = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleMemberStatusChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.memberStatus = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleCustomerTypesChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.customerType = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleCustomerGenderChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.customerGender = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleJenisPertanyaanChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.jenisPertanyaan = event.target.value;

        let findQuestion = this.JENIS_PERTANYAAN.find(item => item.question === dataOrder.jenisPertanyaan);

        if(findQuestion){
            dataOrder.levelCustomer = findQuestion.parent;
        }

        this.setState({dataOrder: dataOrder});
    }

    handleLevelCustomerChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.levelCustomer = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleJawabanCustomerChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.jawabanCustomer = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    handleKesimpulanJawabanCustomerChange = (event) => {
        let dataOrder = {...this.state.dataOrder};
        dataOrder.kesimpulanJawabanCustomer = event.target.value;
        this.setState({dataOrder: dataOrder});
    }

    init = () => {
        let dataProfile = {...this.state.dataOrder};
        dataProfile.customerPhone = this.props.waNumber;
        dataProfile.jawabanCustomer = this.props.messageText;
        this.setState({dataOrder: dataProfile}, () => {
            this.loadCustomerInfo();
        })
    }

    componentDidMount() {
        this.init();
    }

    render() { 
        return (
            <Paper className='padding-5' style={{margin: "10px", backgroundColor: "#d5dae1"}} >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-10">
                            <h4>Customer Profile</h4>
                        </div>
                        <div className="col-2 text-right">
                            <div className="btn btn-sm btn-danger" onClick={this.props.onCloseDrawer}>
                                <Close />
                            </div>
                        </div>
                    </div>
                    <small>
                        Pastikan isi form profile customer dengan benar, karena akan direview oleh supervisor dan manager operations
                    </small>
                    {/* <div className="btn btn-warning" onClick={this.extractFormOrderData}>Extract</div> */}
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}} />

                    <Paper className='padding-5 m-bottom-10'>
                        <h4>Customer Information</h4>
                        {
                            this.state.isLoadingCustomerInfo ? (<LinearProgress style={{marginTop: "10px", marginBottom: "10px"}} />) : (<Divider style={{marginTop: "10px", marginBottom: "10px"}} />)
                        }
                        
                        
                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    error={false}
                                    id="txt-cust-email"
                                    label="Customer Email"
                                    defaultValue=""
                                    value={this.state.dataOrder.customerEmail}
                                    onChange={this.handleCustomerEmailChange}
                                    helperText=""
                                    fullWidth
                                    // variant="filled"
                                    required
                                    size='small'
                                    className='m-bottom-10'
                                    style={{marginBottom: "10px"}}
                                    type='email'
                                    disabled
                                />
                                <TextField
                                    error={false}
                                    id="txt-cust-phone"
                                    label="Customer Phone"
                                    defaultValue=""
                                    value={this.state.dataOrder.customerPhone}
                                    onChange={this.handleCustomerPhoneChange}
                                    helperText=""
                                    fullWidth
                                    // variant="filled"
                                    required
                                    size='small'
                                    className='m-bottom-10'
                                    style={{marginBottom: "10px"}}
                                    type='phone'
                                    disabled
                                />
                                <TextField
                                    error={false}
                                    id="txt-cust-name"
                                    label="Customer Name"
                                    defaultValue=""
                                    value={this.state.dataOrder.customerName}
                                    onChange={this.handleCustomerNameChange}
                                    helperText=""
                                    fullWidth
                                    // variant="filled"
                                    required
                                    size='small'
                                    className='m-bottom-10'
                                    style={{marginBottom: "10px"}}
                                    disabled
                                />
                                
                                
                            </div>
                            <div className="col-6">
                            <FormControl fullWidth size='small' className='m-bottom-10' disabled
                                    style={{marginBottom: "10px"}}>
                                    <InputLabel id="demo-simple-select-label">Member Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="select-member-status"
                                        value={this.state.dataOrder.memberStatus}
                                        label="Member Status"
                                        onChange={this.handleMemberStatusChange}
                                    >
                                        {
                                            this.MEMBER_STATUS.map((item) => {
                                                return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth size='small' className='m-bottom-10' disabled
                                    style={{marginBottom: "10px"}}>
                                    <InputLabel id="demo-simple-select-label">Customer Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="select-customer-type"
                                        value={this.state.dataOrder.customerType}
                                        label="Customer Type"
                                        onChange={this.handleCustomerTypesChange}
                                    >
                                        {
                                            this.CUSTOMER_TYPES.map((item) => {
                                                return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth size='small' className='m-bottom-10' disabled
                                    style={{marginBottom: "10px"}} required>
                                    <InputLabel id="demo-simple-select-label">Customer Gender</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="select-member-status"
                                        value={this.state.dataOrder.customerGender}
                                        label="Customer Gender"
                                        onChange={this.handleCustomerGenderChange}
                                    >
                                        {
                                            this.GENDER.map((item) => {
                                                return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                {/* <div className='border rounded padding-5' style={{backgroundColor: "#deecfc"}}>
                                    <strong>Order Message</strong>
                                    {this.state.extractedDataOrder}
                                </div> */}
                            </div>
                        </div>
                    </Paper>
                    <div className='container-fluid m-bottom-10'>
                        Autofill hanya bisa digunakan untuk 1 item produk tiap 1 CO
                    </div>
                    <Paper className='padding-5 m-bottom-10'>
                        <div className="row">
                            <div className="col-12">
                                <h4>Customer Profile Form</h4>
                                <Divider className='m-bottom' />
                                <br />
                                <div>
                                    <div className="row">
                                        <div className="col-6">
                                            <FormControl fullWidth size='small' className='m-bottom-10' 
                                                style={{marginBottom: "10px"}}>
                                                <InputLabel id="demo-simple-select-label">Jenis Pertanyaan</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="select-member-status"
                                                    value={this.state.dataOrder.jenisPertanyaan}
                                                    label="Member Status"
                                                    onChange={this.handleJenisPertanyaanChange}
                                                >
                                                    {
                                                        this.getJenisPertanyaan()?.map((item) => {
                                                            return <MenuItem value={item.question}>{item.question}</MenuItem>;
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth size='small' className='m-bottom-10' 
                                                style={{marginBottom: "10px"}}
                                                disabled>
                                                <InputLabel id="demo-simple-select-label">Member Status</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="select-member-status"
                                                    value={this.state.dataOrder.levelCustomer}
                                                    label="Member Status"
                                                    onChange={this.handleLevelCustomerChange}
                                                >
                                                    {
                                                        this.LEVEL_CUSTOMER.map((item) => {
                                                            return <MenuItem value={item}>{item}</MenuItem>;
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-6">
                                            <TextField
                                                error={false}
                                                id="txt-jawaban-customer"
                                                label="Jawaban Customer"
                                                defaultValue=""
                                                value={this.state.dataOrder.jawabanCustomer}
                                                onChange={this.handleJawabanCustomerChange}
                                                helperText=""
                                                fullWidth
                                                disabled
                                                // variant="filled"
                                                required
                                                size='small'
                                                className='m-bottom-10'
                                                style={{marginBottom: "10px"}}
                                                type='text'
                                                multiline
                                            />
                                            <TextField
                                                error={false}
                                                id="txt-kesimpulan-jawaban-customer"
                                                label="Kesimpulan Jawaban Customer"
                                                defaultValue=""
                                                value={this.state.dataOrder.kesimpulanJawabanCustomer}
                                                onChange={this.handleKesimpulanJawabanCustomerChange}
                                                helperText=""
                                                fullWidth
                                                // variant="filled"
                                                required
                                                size='small'
                                                className='m-bottom-10'
                                                style={{marginBottom: "10px"}}
                                                type='text'
                                            />
                                        </div>
                                    </div>
                                    <div className="" style={{paddingTop: "10px", paddingBottom: "10px"}}>
                                        {
                                            this.state.isSubmitting === true ? 
                                                (<LinearProgress />) : 
                                                (<div className="btn btn-block btn-primary" onClick={this.handleProceed}>Submit</div>)
                                        }
                                        
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </Paper>
                </div>
                <Snackbar
                    // anchorOrigin={this.snackbarPosition()}
                    open={this.state.showSnackbar}
                    onClose={() => {this.setState({showSnackbar: false})}}
                    message={this.state.snackbarMessage}
                    autoHideDuration={6000}
                    // key={vertical + horizontal}
                />
                <Dialog
                    open={this.state.isDialogNotFoundOpen}
                    onClose={this.toggleDialogNotFound}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Konfirmasi!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Customer <br /><br />
                            Email : {this.state.dataOrder.customerEmail} <br />
                            Phone: {this.state.dataOrder.customerPhone} <br /><br />
                            tidak ditemukan di Lavender
                        </DialogContentText>
                        {/* <Divider /> */}
                        <br />
                        <small><strong>Note</strong></small><br />
                        <small><i>Form ini hanya untuk customer yang sudah ada di lavender</i></small>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleDialogNotFound}>Tutup</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showDialogSubmitSuccess}
                    onClose={this.toggleDialogSubmitSuccess}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Berhasil!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Submission informasi customer berhasil dan akan segera direview
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleDialogSubmitSuccess}>Tutup</Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        );
    }
}
 
export default CustomerProfile;