import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, CircularProgress} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Search } from '@mui/icons-material';
import DateRangePicker from '../../customlib/DateRangePicker';

class ByCityByCustomerByCategoryByUrl extends Component {
    state = { 
        contactLeads : [],
        contactLeadsDisplay : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        value: '0',
        isTeleReport: true,
        cityList: [],
        selectedCity: "",
        contactLeadsHourly: [],
        selectedTimeIndex: 0,
        isLoading: false,
        totalAllAcquisition: 0,
        totalAllRetention: 0
    }
    
    componentDidMount() {
        this.getContactLeads();
    }

    getContactLeads = () => {
      console.log("STARTDATE : ",this.state.startDate);
      var userData = Global.get();
      console.log("USERDATA : ", userData);
      console.log("USERDATA", userData);

      var params = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        appProfileId: Global.getAppProfile()._id,
        userId: userData.user.id,
        reportType: "TYPE_BYCITY_BYCUSTOMER_BYPRODUCTCATEGORY"
      };

      console.log("PARAMS", params);

      this.setState({contactLeads: [], contactLeadsHourly: [], contactLeadsDisplay: [], cityList: [], isLoading: true});

      axios
        .post(Api.getLeadsReportSummary(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("CONTACTLEADS : ", response.data);
          console.log("USERDATA : ", "wkwkwk");
          
          var tmpContactLeads = response.data.data;
          var tmpCityList = [];
          
          // if(userData.admin === false){
          //   tmpContactLeads = tmpContactLeads.filter(element => element.salesName === userData.user.name);
          // }

          var tmpContactLeadsAll = [];
          var tmpContactLeadsHourly = [
              {
                id: "24000300",
                label: "24:00 - 03:00",//2400 - 2700 am
                count: 0,
                detail: [],
                countAcquisition: 0,
                countRetention: 0
              },
              {
                id: "03010600",
                label: "03:01 - 06:00",//2701 - 3000 am
                count: 0,
                detail: [],
                countAcquisition: 0,
                countRetention: 0
              },
              {
                id: "06010900",
                label: "06:01 - 09:00",//3001 - 3300 am
                count: 0,
                detail: [],
                countAcquisition: 0,
                countRetention: 0
              },
              {
                id: "09011200",
                label: "09:01 - 12:00",//3301 - 3600 am
                count: 0,
                detail: [],
                countAcquisition: 0,
                countRetention: 0
              },
              {
                id: "12011600",
                label: "12:01 - 16:00",//3601 - 4000 pm
                count: 0,
                detail: [],
                countAcquisition: 0,
                countRetention: 0
              },
              {
                id: "16011800",
                label: "16:01 - 18:00",//4001 - 4200 pm
                count: 0,
                detail: [],
                countAcquisition: 0,
                countRetention: 0
              },
              {
                id: "18012100",
                label: "18:01 - 21:00",//4201 - 4500 pm
                count: 0,
                detail: [],
                countAcquisition: 0,
                countRetention: 0
              },
              {
                id: "21012359",
                label: "21:01 - 23:59",//4501 - 4759 pm
                count: 0,
                detail: [],
                countAcquisition: 0,
                countRetention: 0
              },
            ]
          
          let totalAcquisition = 0;
          let totalRetention = 0;
          tmpContactLeads.forEach(lead => {
            lead.leadsCount = lead.leads.length;
            tmpCityList.push(lead.city);
            for(var i=0; i<lead.leads.length; i++){
              var originalTime = Global.formatDateTimeToStrFormat(lead.leads[i].lead_created_at, "hh:mm A");
              var timeAndAmPm = originalTime.split(" ");
              var time = timeAndAmPm[0];
              var amPm = timeAndAmPm[1];
              var convertedTime = this.convertTime12to24(originalTime);

              var timeValue = parseInt(convertedTime.replaceAll(":", ""));

              console.log("TIMEVALUE", timeValue);

              if(timeValue <= 2359){
                timeValue += 2400;
              }
              
              let tmpIndex = -1;

              if(timeValue >= 2400 && timeValue <= 2700){
                tmpContactLeadsHourly[0].count += 1;
                tmpContactLeadsHourly[0].detail.push(lead.leads[i]);
                tmpIndex = 0;
              }else if(timeValue >= 2701  && timeValue <= 3000){
                tmpContactLeadsHourly[1].count += 1;
                tmpContactLeadsHourly[1].detail.push(lead.leads[i]);
                tmpIndex = 1;
              }else if(timeValue >= 3001 && timeValue <= 3300){
                tmpContactLeadsHourly[2].count += 1;
                tmpContactLeadsHourly[2].detail.push(lead.leads[i]);
                tmpIndex = 2;
              }else if(timeValue >= 3301 && timeValue <= 3600){
                tmpContactLeadsHourly[3].count += 1;
                tmpContactLeadsHourly[3].detail.push(lead.leads[i]);
                tmpIndex = 3;
              }else if(timeValue >= 3601 && timeValue <= 4000){
                tmpContactLeadsHourly[4].count += 1;
                tmpContactLeadsHourly[4].detail.push(lead.leads[i]);
                tmpIndex = 4;
              }else if(timeValue >= 4001 && timeValue <= 4200){
                tmpContactLeadsHourly[5].count += 1;
                tmpContactLeadsHourly[5].detail.push(lead.leads[i]);
                tmpIndex = 5;
              }else if(timeValue >= 4201 && timeValue <= 4500){
                tmpContactLeadsHourly[6].count += 1;
                tmpContactLeadsHourly[6].detail.push(lead.leads[i]);
                tmpIndex = 6;
              }else if(timeValue >= 4501 && timeValue <= 4759){
                tmpContactLeadsHourly[7].count += 1;
                tmpContactLeadsHourly[7].detail.push(lead.leads[i]);
                tmpIndex = 7;
              }

              let acquisition = lead.leads[i].is_tele_customer ? 0 : 1;
              let retention = lead.leads[i].is_tele_customer ? 1 : 0;

              tmpContactLeadsHourly[tmpIndex].countAcquisition += acquisition;
              tmpContactLeadsHourly[tmpIndex].countRetention += retention;

              totalAcquisition += acquisition;
              totalRetention += retention;

              // lead.leads[i].createdAt = originalTime;
              // tmpContactLeadsAll.push(lead.leads[i]);
            }
            
          });

          this.setState({
            contactLeads: tmpContactLeads, 
            contactLeadsHourly: tmpContactLeadsHourly, 
            contactLeadsDisplay: tmpContactLeads, 
            totalAllAcquisition: totalAcquisition,
            totalAllRetention: totalRetention,
            cityList: tmpCityList, 
            isLoading: false},() => {
            console.log("HOURLY", this.state.contactLeadsHourly);
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    };

    convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");
     
      let [hours, minutes, seconds] = time.split(":");
     
      if (hours === "12") {
        hours = "00";
      }
     
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
     
      return `${hours}:${minutes}:${seconds}`;
    };

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    handleCityOnChange = (city) => {
      console.log("SELECTEDSALES", city);
      var tmpContactLeads = [...this.state.contactLeads];
      if(city !== null){
        tmpContactLeads = tmpContactLeads.filter(element => element.city === city);
      }

      this.setState({selectedCity: city, contactLeadsDisplay: tmpContactLeads});
    }

    handleChangeSelectedTimeIndex = (value) => {
      this.setState({selectedTimeIndex: value});
    }

    getIndexById = (id) => {
      for(var i=0; i<this.state.contactLeadsHourly.length;i++){
        if(this.state.contactLeadsHourly[i].id === id){
          return i;
        }
      }
    }

    columns = [
        { id: 'salesName', label: 'Kota', minWidth: 170 },
        { id: 'leadsCounta', label: '', minWidth: 100 }
    ];

    render() { 
        return ( 
          <Paper style={{padding:20}}>
                <div className="container-fluid">
                  <div className="panel panel-default">
                      <div className="panel-heading">
                          <div className="panel-title">
                              {/* <h1>Setup Contact</h1> */}
                          </div>
                      </div>
                      <div className="panel-body">
                          <div className="row align-items-center">
                              <div className="col-3">
                                  {/* <strong>Total {this.state.contactLeadsDisplay.length} Kota</strong> */}
                              </div>
                              <div className="col-3">
                              {/* <Autocomplete
                                    id="free-solo-2-demo"
                                    // disableClearable
                                    freeSolo 
                                    options={this.state.cityList.map((option) => {
                                        // console.log("LABEL : ", option);
                                        return String(option);
                                    })}
                                    onChange={(event, newValue) => {
                                        // console.log("NEWVALUE : ", event);
                                        this.handleCityOnChange(newValue);
                                      }} 
                                    value={this.state.selectedCity}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Pilih Kota"
                                        margin="0"
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                    />
                                    )}
                                /> */}
                              </div>
                              <div className="col-5 text-right" style={{borderRadius: 10, padding: 5}}>
                                <DateRangePicker onDateChanged={this.handleDateChanged} />
                              </div>
                              <div className="col-1">
                              {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.getContactLeads()}} className="btn btn-primary" style={{padding: 8}}><Search /></div>)
                                }
                            </div>
                          </div>
                          <br />
                          
                          {/* <h5>
                            Perolehan berdasarkan selang waktu
                          </h5> */}

                          <Paper elevation={0} className="root">
                            <div className="panel panel-default">
                            {/* <div className="panel-heading">
                                <div className="panel-title">
                                
                                </div>
                            </div> */}
                      <div className="panel-body">
                          <div className="row" style={{marginTop: "10px", marginBottom: "10px"}}>
                            <div className="col-6 text-center">
                              <h5>Total Acquisition : {this.state.totalAllAcquisition}</h5>
                            </div>
                            <div className="col-6 text-center">
                              <h5>Total Retention : {this.state.totalAllRetention}</h5>
                            </div>
                          </div>
                          <Paper className="root">
                              <TableContainer>
                                  <Table aria-label="table" size='small'>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell width="20px" />
                                      {this.state.contactLeadsHourly.map((column) => (
                                          <TableCell
                                              key={column.id}
                                              align={column.align}
                                              // style={{ minWidth: column.minWidth }}
                                          >
                                          <span style={{fontSize: 12, fontWeight: "bold"}}>{column.label}</span>
                                          </TableCell>
                                      ))}
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell width={"20px"} />
                                        {this.state.contactLeadsHourly.map((column) => (
                                            <TableCell
                                                key={column.id+"a"}
                                                align={column.align}
                                                // style={{ minWidth: column.minWidth }}
                                            >
                                            {/* {column.count} */}
                                            {
                                              this.state.selectedTimeIndex === this.getIndexById(column.id)? (
                                                <div className="btn btn-secondary">
                                                  <strong>{column.count}</strong>
                                                </div>
                                              ) : (<div className="btn btn-primary" onClick={() => {this.handleChangeSelectedTimeIndex(this.getIndexById(column.id))}}>{column.count}</div>)
                                            }
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                      {/* {this.state.contactLeads.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                      return (
                                          <Row key={row.salesId} row={row} />
                                      );
                                      })} */}
                                  </TableBody>
                                  </Table>
                              </TableContainer>
                              
                          </Paper>

                          {
                            this.state.contactLeadsHourly.length > 0 ? (
                              <Paper>
                                <Table size="small" aria-label="purchases" style={{marginTop: "20px"}}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Kota</TableCell>
                                      <TableCell>Pelanggan</TableCell>
                                      <TableCell>Tipe</TableCell>
                                      <TableCell>Kategori Produk</TableCell>
                                      <TableCell>Waktu</TableCell>
                                      <TableCell>URL</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.contactLeadsHourly[this.state.selectedTimeIndex].detail.map((historyRow) => (
                                      <TableRow key={historyRow.id}>
                                        <TableCell component="th" scope="row">
                                          <div>{historyRow.city}</div>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          <div>{historyRow.customer_name}</div>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          <div>{historyRow.is_tele_customer ? "RETENTION" : "ACQUISITION"}</div>
                                        </TableCell>
                                        <TableCell align="left">
                                          {historyRow.product_category === "" ? "---" : historyRow.product_category}
                                        </TableCell>
                                        <TableCell align="left">
                                          {Global.formatDateTimeToStrTime24(historyRow.lead_created_at)}
                                        </TableCell>
                                        <TableCell align="left">
                                          {historyRow.source_url}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                
                                {/* <div className="row" style={{margin: "10px"}}>
                                  <div className="col-6" style={{fontWeight: "bold"}}>
                                    Total Acquisition : {this.state.contactLeadsHourly[this.state.selectedTimeIndex].countAcquisition}
                                  </div>
                                  <div className="col-6" style={{fontWeight: "bold"}}>
                                    Total Retention : {this.state.contactLeadsHourly[this.state.selectedTimeIndex].countRetention}
                                  </div>
                                </div> */}

                              </Paper>
                            ) : (<></>)
                          }
                          
                      </div>
                      <div className="panel-footer">
                          
                      </div>
                  </div>
                          </Paper>
                          {/* <br />
                          <h5>Perolehan URL Iklan</h5>
                          <Paper className="root">
                              <TableContainer>
                                  <Table aria-label="sticky table">
                                  <TableHead>
                                      <TableRow>
                                          <TableCell />
                                      {this.columns.map((column) => (
                                          <TableCell
                                              key={column.id}
                                              align={column.align}
                                              style={{ minWidth: column.minWidth }}
                                          >
                                          {column.label}
                                          </TableCell>
                                      ))}
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {this.state.contactLeadsDisplay.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                      return (
                                          <Row key={row.salesId} row={row} />
                                      );
                                      })}
                                  </TableBody>
                                  </Table>
                              </TableContainer>
                              
                          </Paper> */}
                          
                      </div>
                      <div className="panel-footer">
                                      
                      </div>
                  </div>
                </div>
              </Paper>
         );
    }
}
 
export default ByCityByCustomerByCategoryByUrl;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" align='left'>
            {row.city}
          </TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                {/* <Typography variant="h6" gutterBottom component="div">
                  Berdasarkan Kota dan Kategori Produk
                </Typography> */}
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pelanggan</TableCell>
                      <TableCell>Kategori Produk</TableCell>
                      <TableCell>URL</TableCell>
                      <TableCell>Waktu</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.leads.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        <TableCell component="th" scope="row">
                          <div>{historyRow.customer_name}</div>
                        </TableCell>
                        <TableCell align="left">{historyRow.product_category === "" ? "---" : historyRow.product_category}</TableCell>
                        <TableCell align="left">
                          {historyRow.source_url}
                        </TableCell>
                        <TableCell align="left">
                        {Global.formatDateTimeToStrDateTime24(historyRow.lead_created_at)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }