import { React, Component } from "react";
import "./Compose.css";
import { FaPaperPlane } from "react-icons/fa";
import { Assignment, AttachFile, RecordVoiceOver} from "@mui/icons-material";
import ToolTip from "@mui/material/Tooltip";
import Global from "../../customlib/Global";
import { Paper } from "@mui/material";

class Compose extends Component {
  state = {
    customerName: ""
  };

  onKeyDownHandler = (e) => {

    if(e.keyCode === 13 && e.ctrlKey){
        this.props.onSendMessage();
        console.log("CTRL + ENTER");
    }else if(e.keyCode === 13 && e.shiftKey){
        this.props.onSendMessage();
        console.log("CTRL + SHIFT");
    }else if (e.keyCode === 13) {
        console.log("ENTER");
    }
  };

  getStyleBtnCs = () => {
    let conversation = this.props.currentConversation;

    let btnDanger = "btn btn-warning";
    let btnOff = "btn btn-secondary";

    return conversation.csHelpState !== undefined && conversation.csHelpState !== "OFF" ? btnDanger : btnOff;

  }

  getStyleBtnLabel = () => {
    let conversation = this.props.currentConversation;

    let btnDanger = "btn btn-warning";
    let btnOff = "btn btn-secondary";

    return conversation.contactLabel !== undefined && conversation.contactLabel !== "" ? btnDanger : btnOff;

  }

  handleOnChange = (event) => {
    this.setState({ customerName: event.target.value });
  };

  componentDidMount() {
    console.log("CURRENT", this.props.currentConversation);
  }

  componentDidUpdate(){
    console.log("CURRENT", this.props.currentConversation);
  }

  render() {
    return (
      <Paper elevation={3}>
      <div className="compose">
        {/* {
          this.props.currentConversation.customerName === "" ? 
          (
            <div className="container" style={{marginBottom: "10px", position: "absolute", zIndex: 10000, bottom: "60px"}}>
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4 border rounded bg-secondary" style={{padding: "10px", boxShadow: "5px 5px #DEDEDE"}}>
                  <div className="bg-light border rounded" style={{padding: "20px"}}>
                    <TextField
                      style={{marginBottom: "35px"}}
                      type="text"
                      onChange={this.handleOnChange}
                      className="form-control bg-light"
                      placeholder="Ketikkan nama customer disini"
                      label="Nama Customer"
                    />
                    <div className="btn btn-warning btn-block" onClick={() => this.props.onSaveCustomerName(this.state.customerName)}>Simpan Nama Cutomer</div>
                  </div>
                </div>
                <div className="col-4"></div>
              </div>
            </div>
          ) : 
          this.props.currentConversation.customerName === undefined || this.props.currentConversation.customerName === null ? (<></>) : (<></>)
          
        } */}
        
        <div
          className="input-group"
          style={{ marginLeft: "5px", marginTop: 0, marginRight: 15 }}
        >
            <div className="input-group-append">

            <ToolTip title="Label Customer">
                <button
                  onClick={() => {
                      console.log(Global.isCustomerService(), Global.isCustomerServiceSpv());
                      if(Global.isCustomerService() || Global.isCustomerServiceSpv()){
                        if(this.props.currentConversation.contactLabel !== undefined && this.props.currentConversation.contactLabel === Global.labelPaid()){
                          this.props.onSetContactLabelClick();
                        }
                      }else if(this.props.currentConversation.contactLabel === undefined || this.props.currentConversation.contactLabel === "" || this.props.currentConversation.contactLabel === Global.labelUnpaid() || this.props.currentConversation.contactLabel === Global.labelDp() || this.props.currentConversation.contactLabel === Global.labelCancel() || this.props.currentConversation.contactLabel === Global.labelPending()){
                        console.log("CONVERSATION : ", this.props.currentConversation);
                        // this.props.onCsHelpButtonClick();
                        this.props.onSetContactLabelClick();
                      }else if(Global.get().admin === true){
                        this.props.onSetContactLabelClick();
                      }
                      // this.props.onSetContactLabelClick();
                      console.log(this.props.currentConversation)
                    }
                  }
                  className={this.getStyleBtnLabel()}
                  style={{borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"}}
                  type="button"
                  id="button-addon2"
                >
                  {
                    this.props.currentConversation.contactLabel === undefined  || this.props.currentConversation.contactLabel === "" ? 
                    (
                      <div><div className="badge badge-pill badge-dark">Set Label</div></div>
                    ):
                    (
                      <div></div>
                    )
                  }

                  {
                    this.props.currentConversation.contactLabel !== undefined  && this.props.currentConversation.contactLabel !== "" ? 
                    (
                      <div><div className={Global.getLabelClasses(this.props.currentConversation.contactLabel)}>{this.props.currentConversation.contactLabel}</div></div>
                    ):
                    (
                      <div></div>
                    )
                  }

                </button>
              </ToolTip>
              {
                this.props.currentConversation.contactLabel !== undefined  && this.props.currentConversation.contactLabel === "DP" ? 
                (
                  <ToolTip title="Status bantuan CS">
                    <button
                      onClick={() => {

                          if(this.props.currentConversation.csHelpState === undefined || this.props.currentConversation.csHelpState === "OPEN" || this.props.currentConversation.csHelpState === "OFF"){
                            this.props.onCsHelpButtonClick();
                          }else{
                            if(Global.get().admin === true || Global.isCustomerServiceSpv()){
                              this.props.onCsHelpButtonClick();
                            }
                          }                      
                          console.log(this.props.currentConversation)
                        }
                      }
                      className={this.getStyleBtnCs()}
                      type="button"
                      id="button-addon2"
                    >

                      {
                        this.props.currentConversation.csHelpState !== undefined && this.props.currentConversation.csHelpState !== "OFF" ? 
                        (
                          <RecordVoiceOver
                            key="csHelp"
                            className="text-center"
                            style={{ color: "#FFFFFF" }}
                          />
                        ) : 
                        (
                          <RecordVoiceOver
                            key="csHelp"
                            className="text-center"
                            style={{ color: "#FFFFFF" }}
                          />
                        )
                      }

                      {
                        this.props.currentConversation.csHelpState === undefined | this.props.currentConversation.csHelpState === "OFF" ? 
                        (
                          <div><small>Oper ke  CS</small></div>
                        ):
                        (
                          <div></div>
                        )
                      }

                      {
                        this.props.currentConversation.csHelpState === "OPEN" ? 
                        (
                          <div><small>Menunggu CS</small></div>
                        ):
                        (
                          <div></div>
                        )
                      }

                      {
                        this.props.currentConversation.csHelpState === "SOLVING" ? 
                        (
                          <div><small>Sedang Diproses</small></div>
                        ):
                        (
                          <div></div>
                        )
                      }

                      {
                        this.props.currentConversation.csHelpState === "SOLVED" ? 
                        (
                          <div><small>CS Selesai</small></div>
                        ):
                        (
                          <div></div>
                        )
                      }

                    </button>
                  </ToolTip>
                ) : 
                (<></>)
              }
            
              <ToolTip title="Attachment">
                <button
                  onClick={() =>
                    this.props.onOpenDropZone()
                  }
                  className="btn btn-danger"
                  type="button"
                  id="button-addon2"
                >
                  <AttachFile
                    key="composesend"
                    className="text-center"
                    style={{ color: "#FFFFFF" }}
                  />
                </button>
              </ToolTip>
              <ToolTip title="Pesan Template">
                <button
                  onClick={() =>
                    this.props.onTemplateButtonClick()
                  }
                  className="btn btn-danger"
                  type="button"
                  id="button-addon2"
                >
                  <Assignment
                    key="sendtemplate"
                    className="text-center"
                    style={{ color: "#FFFFFF" }}
                  />
                </button>
              </ToolTip>
            {/* { props.rightItems } */}
          </div>
          {/* <input
            onKeyDown={this.onKeyDownHandler}
            value={this.props.messageValue}
            onChange={this.props.onChange}
            type="text"
            className="form-control"
            placeholder="Type a message"
            aria-label="Type amessage"
            aria-describedby="button-addon2"
          /> */}
          <textarea
            onKeyDown={this.onKeyDownHandler}
            value={this.props.messageValue}
            onChange={this.props.onChange}
            type="text"
            className="form-control"
            placeholder="Ketikkan pesan anda di sini"
            aria-label="Ketikkan pesan anda di sini"
            aria-describedby="button-addon2"
          />
          <div className="input-group-append">
            <button
              onClick={() =>
                this.props.onSendMessage("halo", this.props.currentConversation)
              }
              className="btn btn-primary"
              type="button"
              id="button-addon2"
            >
              <FaPaperPlane
                key="composesend"
                className="text-center"
                style={{ color: "#FFFFFF" }}
              />
              <br />
              {this.props.messageValue && this.props.messageValue.length > 0 ? (<small style={{fontSize: "10px"}}>{this.props.messageValue.trim().length}</small>) : ""}
            </button>
            {/* { props.rightItems } */}
          </div>
        </div>
      </div>
      </Paper>
    );
  }
}

export default Compose;

// export default function Compose(props) {
//     return (
//       <div className="compose">
//           {/* <div className="row">
//             <div className="col-11"> */}
//                 {/* <input
//                     type="text"
//                     style={{width: "95%"}}
//                     //   className="compose-input"
//                     placeholder="Type a message"
//                     /> */}
//             <div className="input-group" style={{marginLeft: 10, marginTop: 0, marginRight: 15}}>
//                 <input value={props.messageValue} onChange={props.onChange} type="text" className="form-control" placeholder="Type a message" aria-label="Type amessage" aria-describedby="button-addon2" />
//                 <div className="input-group-append">
//                     <button onClick={() => props.onSendMessage("halo", props.currentConversation)} className="btn btn-primary" type="button" id="button-addon2">
//                         <FaPaperPlane key="composesend" className="text-center" style={{color: "#FFFFFF"}} />
//                     </button>
//                     {/* { props.rightItems } */}

//                 </div>
//             </div>
//             {/* </div>
//             <div className="col-1"> */}
//                 {/* { props.rightItems } */}
//             {/* </div> */}
//           {/* </div> */}
//       </div>
//     );
// }
