import "./App.css";
import React, { Component } from "react";
import Messenger from "./components/Messenger";
import Login from "./components/Login/login";
import Global from "./customlib/Global";
import Api from "./customlib/Api";
import {
    BrowserRouter as Router,
    Route,
    Switch
  } from "react-router-dom";
import socketIOClient from "socket.io-client";
import SettingsResponsive from "./components/Settings/SettingsResponsive";
import DataPending from "./components/DataPending";
import DailyClosingRateReport from "./components/ContactLeadsReport/DailyClosingRateReport";
import base64 from "base-64";
import utf8 from "utf8";

import { withOneTabEnforcer } from "react-one-tab-enforcer"
import OneTabWarning from "./components/OneTabWarning";
import DataCancelDashboard from "./components/DataPending/DataCancelDashboard";
import crypto from "crypto-js";
import MonitoringPerpindahanContact from "./components/Monitoring/PerpindahanContact";
import DataPendingLeadsTabs from "./components/DataPending/DatapendingLeadsTabs";
import AnalisaDataCancel from "./components/DataPending/AnalisaDataCancel";
import DashboardPerformaSales from "./components/DashboardPerformaSales";

var socket;
class App extends Component {
  state = {
    auth: {},
    validAuth: false,
  };

  constructor() {
    super();

    let lotusConsoleEnabled = localStorage.getItem("lotus_console_enabled");
    console.log("LOTUS_DEBUG_MODE", lotusConsoleEnabled);

    if(lotusConsoleEnabled !== null && lotusConsoleEnabled !== undefined){
      console.log("CONSOLE ITEM", lotusConsoleEnabled);
      if (lotusConsoleEnabled === "false") {
        console.log("CONSOLE DISABLED");
        console.log = () => {};
        console.debug = () => {};
        console.info = () => {};
        console.warn = () => {};
        console.error = () => {};
      }
    }else{
      console.log("CONSOLE API");
      if (!Api.isConsoleEnabled) {
        console.log("CONSOLE ENABLED");
        console.log = () => {};
        console.debug = () => {};
        console.info = () => {};
        console.warn = () => {};
        console.error = () => {};
      }
    }

    socket = socketIOClient(Api.getSocketUrl());
    Global.setSocket(socket);
    // console.log("SOCKET INITIATED AT : " + Api.getSocketUrl());
    //   Global.setSocket(socket);
  }

  componentDidMount() {

    this.readTeleQueryParams();
    this.readFuQueryParams();
    this.readOpenChatQueryParams();
    this.initAppState();

  }
  
  readTeleQueryParams = () => {
    // const queryParams = new URLSearchParams(window.location.search);
    // let refValue = queryParams.get("ref");
    // console.log("ORIREFVALUE", refValue);

    // if(refValue !== null && refValue !== undefined && refValue.length > 0){
    //   try {
    //     let refData = base64.decode(queryParams.get("ref"));
    //     refData = utf8.decode(refData);
    //     let refDataParsed = JSON.parse(refData);
    //     localStorage.setItem("ref", JSON.stringify(refDataParsed));
    //     console.log("QUERYPARAMS", refDataParsed);
    //   } catch (error) {
    //     localStorage.removeItem("ref");
    //   }
    // }else{
    //   localStorage.removeItem("ref");
    // }
  }

  readFuQueryParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let refValue = queryParams.get("fu");
    console.log("ORIREFVALUE", refValue);

    if(refValue !== null && refValue !== undefined && refValue.length > 0){
      try {
        // let refData = Buffer.from(queryParams.get("fu"), 'base64').toString('utf-8');
        let refData = base64.decode(queryParams.get("fu"));
        refData = utf8.decode(refData);
        let refDataParsed = JSON.parse(refData);
        localStorage.setItem("fu", JSON.stringify(refDataParsed));
        console.log("QUERYPARAMS", refDataParsed);
        // let history = useHistory();
        // history.push("/");
      } catch (error) {
        localStorage.removeItem("fu");
      }
    }else{
      localStorage.removeItem("fu");
    }
  }

  readOpenChatQueryParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let refValue = queryParams.get("openChat");
    console.log("ORIREFVALUE", refValue);

    if(refValue !== null && refValue !== undefined && refValue.length > 0){
      try {
        // let refData = Buffer.from(queryParams.get("fu"), 'base64').toString('utf-8');
        let refData = base64.decode(queryParams.get("openChat"));
        refData = utf8.decode(refData);
        let refDataParsed = JSON.parse(refData);
        localStorage.setItem("openChat", JSON.stringify(refDataParsed));
        console.log("QUERYPARAMS_OPENCHAT", refDataParsed);
        // let history = useHistory();
        // history.push("/");
      } catch (error) {
        localStorage.removeItem("openChat");
      }
    }else{
      localStorage.removeItem("openChat");
    }
  }


  initAppState = () => {

    let authData = {};
    try {
      let strAuthCipher = localStorage.getItem("auth");
      console.log("strAuthCipher", strAuthCipher);
      let plainTextBytes = crypto.AES.decrypt(strAuthCipher, Global.getStringRequirements());
      let plainText = plainTextBytes.toString(crypto.enc.Utf8);

      // let authData = JSON.parse(localStorage.getItem("auth"));
      authData = JSON.parse(plainText);  
      // Global.setPropertiesConsoleEnabled(Api.isConsoleEnabled);
    } catch (error) {
      localStorage.removeItem("auth");
      console.log(error);
    }
    
    let validAuth = authData && authData.user && authData.access_token.length > 0;

    // console.log("AUTHDATA", authData, validAuth);
    Global.add(authData);
   this.setState({ auth: authData, validAuth: validAuth }, () => {
      if(validAuth){
        socket = socketIOClient(Api.getSocketUrl());
        Global.setSocket(socket);
        Global.getSocket().on("connect", () => {
          Global.getSocket().emit("register", {
            userId: authData.user.id,
            userName: authData.user.name,
            appProfileId: authData.appProfile._id,
            appLabel: authData.appProfile.label,
            appWaNumber: authData.appProfile.waNumber
          });
        });
      }
    });
    // Global.add(authData);
  };

  handleAuthChange = () => {
    this.initAppState();
  };

  // componentWillUnmount(){
  //   localStorage.setItem("test", "halooo");
  // }

  render() {
    // console.log("STATE_RENDER", this.state);
    // console.log("STATE_AUTH_RENDER", this.state.auth);
    // console.log("STATE_VALIDAUTH_RENDER", this.state.validAuth);
    return (

        
        this.state.validAuth ? 
        (
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Messenger onAuthChange={this.handleAuthChange} />
                    </Route>
                    <Route path="/setting">
                        <SettingsResponsive />
                    </Route>
                    {/* <Route path="/setup-module-training">
                        <SetupModulTraining />
                    </Route> */}
                    <Route path="/data-pending">
                        <DataPending />
                    </Route>
                    <Route path="/closing-rate">
                        <DailyClosingRateReport />
                    </Route>
                    <Route path="/analisis-data-cancel">
                        {/* <DataCancelDashboard /> */}
                        <AnalisaDataCancel />
                    </Route>
                    <Route path="/riwayat-perpindahan-contact">
                        <MonitoringPerpindahanContact />
                    </Route>
                    <Route path="/leads-data-pending">
                        <DataPendingLeadsTabs />
                    </Route>
                    <Route path="/dashboard-performa-sales">
                        <DashboardPerformaSales />
                    </Route>
                </Switch>
            </Router>
        ) : 
        (
            <Login onLogin={this.handleAuthChange} />
        )
    );
  }
}

export default withOneTabEnforcer({appName: "lotus-messaging", OnlyOneTabComponent: OneTabWarning})(App)
// export default App;
