import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { ImportExport } from "@mui/icons-material";
import moment from "moment";
import Global from "../../customlib/Global";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

var dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];

class ExportLeadsReport extends Component {

    getleadsReportData = () => {
        let leadsData = [];
        this.props.leadsReportData.forEach(element => {
            element.leads.forEach(lead => {
                lead.maskedFrom = Global.maskPhoneNumber(lead.from);
                leadsData.push(lead);
            });
        });
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<button className="btn btn-primary"><ImportExport />Export ke Excel</button>}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getleadsReportData} name="Leads Report">
                    <ExcelColumn label="Sales" value="firstContactAssignedToName"/>
                    <ExcelColumn label="Customer" value="fromName"/>
                    <ExcelColumn label="WhatsApp Number" value="maskedFrom"/>
                    <ExcelColumn label="Label" value="label"/>
                    <ExcelColumn label="Tipe Lead" value="tipeLead"/>
                    <ExcelColumn label="Permasalahan" value="permasalahan"/>
                    <ExcelColumn label="Alasan Umum" value="alasanUmum"/>
                    <ExcelColumn label="Alasan Rinci" value="alasanRinci"/>
                    <ExcelColumn label="Kategori Produk" value="productCategory"/>
                    <ExcelColumn label="Kota" value="cityName"/>
                    <ExcelColumn label="Lead Date"
                                  value={(col) => Global.formatDateTimeToStrDateTime24(col.createdAt)}/>
                    <ExcelColumn label="Last Message Date"
                                  value={(col) => Global.formatDateTimeToStrDateTime24(col.lastMessageReceivedAt)}/>
                    
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportLeadsReport;