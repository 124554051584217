import React, { Component } from "react";
import ConversationListItem from "../ConversationListItem";
import Toolbar from "../Toolbar";
import Global from "../../customlib/Global";
import {PendingActions, Stars, Scoreboard as ScoreboardIcon, Settings, Lock, Contacts, AssignmentReturn, NotificationsActive, Assessment, Search, AccessAlarm, VoiceOverOff, ViewModuleOutlined, Snooze, ImportContacts, Timelapse, Navigation, TrendingUp, LegendToggle, ConnectWithoutContact, Notifications, NotificationsOff} from "@mui/icons-material"
import { Link } from "react-router-dom";

import "./ConversationList.css";
import ConversationSearch from "../ConversationSearch";
import CustomerServiceHelp from "../CustomerServiceHelp";
import { Badge, Fab, FormControlLabel, Menu, MenuItem, Paper, Switch, Tooltip as ToolTip } from "@mui/material";
import md5 from "md5";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';

class ConversationList extends Component {

  state = {
    hasUnreadMessageOnly: false,
    openMenuLabel: false,
    anchorElLabel: null,
    anchorElSlow: null,
    openMenuSlow: false,
    anchorElJustRead: null,
    openMenuJustRead: false,
    anchorElUnfinished: null,
    openMenuUnfinished: false,
    anchorElWajibBayar: null,
    openMenuWajibBayar: false,
    anchorEl: null,
    open: false,
    openMenuHasilFu: false,
    anchorElHasilFu: null,
    openMenuChatNggantung: false,
    anchorElChatNggantung: null,
    menuLainnyaExpanded: false
  }

  handleToggleUnreadMessageOnly = () => {
    this.setState({hasUnreadMessageOnly: !this.state.hasUnreadMessageOnly}, () => {
      this.props.onUnreadConversationOnlyChange(this.state.hasUnreadMessageOnly);
    });
  }

  handleOpenCancelContactDashboard = () => {
    // this.setState({open: false, anchorEl: null}, () => {
        this.props.onContactsCancelDashboardClick();
    // });
  }

  handleClosePopupMenuLabel = () => {
    this.setState({openMenuLabel: false, anchorElLabel: null}, () => {
      // this.setState({anchorEl: null});
    });
  };

  handleClosePopupMenuHasilFu = () => {
    this.setState({openMenuHasilFu: false, anchorElHasilFu: null}, () => {
      // this.setState({anchorEl: null});
    });
  };
  
  handleClosePopupMenuChatNggantung = () => {
    this.setState({openMenuChatNggantung: false, anchorElChatNggantung: null}, () => {
      // this.setState({anchorEl: null});
    });
  };

  handleMenuItemClick = (event) => {
    this.setState({open: false, anchorEl: null}, () => {
      // this.setState({anchorEl: null});
      // console.log(this.state);
    });
  };

  handleClosePopupMenu = () => {
    this.setState({open: false, anchorEl: null}, () => {
      // this.setState({anchorEl: null});
    });
  };

  handleModultrainingMenuClicked = (event) => {
    this.setState({anchorEl: event.currentTarget, open: true});
  };

  handleFilterLabelMenuClicked = (event) => {
    this.setState({anchorElLabel: event.currentTarget, openMenuLabel: true});
  };

  handleChangeFilterLabel = (label) => {
    console.log("FILTERLABEL : ", label);
    this.setState({openMenuLabel: false, anchorElLabel: null}, () => {
        this.props.onFilterLabelChange(label);
    });
  }
  
  handleFilterHasilFuMenuClicked = (event) => {
    this.setState({anchorElHasilFu: event.currentTarget, openMenuHasilFu: true});
  };

  handleChangeFilterHasilFu = (label) => {
    this.setState({openMenuHasilFu: false, anchorElHasilFu: null}, () => {
        this.props.onFilterHasilFuChange(label);
    });
  }

  handleChangeFilterChatNggantung = (label) => {
    this.setState({openMenuChatNggantung: false, anchorElChatNggantung: null}, () => {
        this.props.onFilterChatNggantungChange(label);
    });
  }

  styleToggle = () =>{
    return this.state.hasUnreadMessageOnly ? "btn btn-block btn-warning" : "btn btn-block btn-secondary";
  }

  styleCsHelpToggle = () =>{
    return this.props.csHelpCount > 0 ? "btn btn-block btn-danger" : "btn btn-block btn-secondary";
  }

  handleClosePopupMenuSlow = () => {
    this.setState({openMenuSlow: false, anchorElSlow: null}, () => {
      // this.setState({anchorEl: null});
    });
  };

  handleSlowResponseMenuClicked = (event) => {
    this.setState({anchorElSlow: event.currentTarget, openMenuSlow: true}, () => {
      this.props.onUpdateSlow();
    });
  };

  handleClosePopupMenuUnfinished = () => {
    this.setState({openMenuUnfinished: false, anchorElUnfinished: null}, () => {
      // this.setState({anchorEl: null});
    });
  };

  handleUnfinishedMenuClicked = (event) => {
    this.setState({anchorElUnfinished: event.currentTarget, openMenuUnfinished: true}, () => {
      this.props.onUpdateUnfinishedChat();
    });
  };

  handleClosePopupMenuWajibBayar = () => {
    this.setState({openMenuWajibBayar: false, anchorElWajibBayar: null}, () => {
      // this.setState({anchorEl: null});
    });
  };

  handleWajibBayarMenuClicked = (event) => {
    this.setState({anchorElWajibBayar: event.currentTarget, openMenuWajibBayar: true}, () => {
      this.props.onUpdateWajibBayar();
    });
  };

  handleChatNggantungMenuClicked = (event) => {
    this.setState({anchorElChatNggantung: event.currentTarget, openMenuChatNggantung: true}, () => {
      this.props.onUpdateChatNggantung();
    });
  };

  handleOpenWajibBayar = () => {
    // this.setState({open: false, anchorEl: null}, () => {
        this.props.onMenuWajibBayarClick();
    // });
  }

  handleOpenDashboardSpv = () => {
    this.setState({open: false, anchorEl: null}, () => {
        this.props.onOpenDashboardTrainingSpvClick();
    });
  }

  handleClosePopupMenuJustRead = () => {
    this.setState({openMenuJustRead: false, anchorElJustRead: null}, () => {
      // this.setState({anchorEl: null});
    });
  };

  handleJustReadMenuClicked = (event) => {
    this.setState({anchorElJustRead: event.currentTarget, openMenuJustRead: true}, () => {
      this.props.onUpdateJustRead();
    });
  };

  handleTogleMenuLainnya = () => {
    if(!this.state.menuLainnyaExpanded){

    }

    let isExpanded = this.state.menuLainnyaExpanded;

    this.setState({menuLainnyaExpanded: !isExpanded}, () => {
      if(!isExpanded){
        this.props.onUpdateJustRead();
        this.props.onUpdateSlow();
      }
    })
  }

  componentDidMount() {
    
  }

  fabStyle = {
    position: 'fixed',
    bottom: 16,
    left: 16,
  };
  

  render() {
    // console.log("MyPROPS : ", this.props);
    return (
      <div className="conversation-list">
        
        <Toolbar
          onClickLeftItem={this.props.onSettingClick}
          onClickRightItem={this.props.onNewClick}
          key="contact-toolbar"
          title={Global.get().user.name + " - " +Global.get().user.dept}
          leftItems={
            [
              Global.get().admin && !Global.isCustomerService() && !Global.isCustomerServiceSpv() ? 
              <ToolTip title="Settings">
                <Link to="/setting"><Settings key="toolbarbtnconfig" /></Link>
              </ToolTip> : 
              <div></div>
            ]
          }
          // leftItems={[Global.get().admin ? <Link to="/setting"><Settings key="toolbarbtnconfig" /></Link> : <Link to="/setting"><Settings key="toolbarbtnconfig" /></Link>]}
          rightItems={
            [
              <ToolTip title="Logout">
                <Lock key="toolbarbtnsignout" style={{color: "#FF0000", cursor: "pointer"}} />
              </ToolTip>
            ]
          }
        />

        {
          this.props.conversationViewMode !== "ALL_CONTACTS" ? 
          (
            <></>
          ) : 
          (
            <Paper elevation={0} sx={{margin: "10px 5px"}}>
              <div className="row" style={{marginLeft: 5, marginRight:5}}>
                <div className="col-12 text-center" style={{paddingBottom: "0px"}}>
                  <strong>
                    {Global.getAppProfile().label}&nbsp;
                  </strong>
                </div>
              </div>

              <div className="row" style={{marginLeft: 5, marginRight:5}}>
                <div className="col-12 text-center" style={{paddingBottom: "5px"}}>
                  <strong>
                    {
                      this.props.isUserAutoleadsOn && Global.isSales() && <div className= {"badge badge-success"}>Autoleads ON</div>
                    }
                    {
                      !this.props.isUserAutoleadsOn && Global.isSales() && <div className= {"badge badge-danger"} >Autoleads OFF</div>
                    }
                  </strong>
                </div>
              </div>
              
              <div className="row" style={{marginLeft: 5, marginRight:5}}>
                {
                  Global.isAdminEpUser() || Global.isAdminTeleUser() || Global.isSales() || Global.isTeleCutomer() ? (<></>) : 
                  (
                    <div className="col-3">
                      <ToolTip title="Daftar Kontak">
                        <div onClick={this.props.onContactsDashboardClick} className="btn btn-block btn-primary">
                          <Contacts />
                        </div>
                      </ToolTip>
                      {/* &nbsp; */}
                    </div>
                  )
                }
                {
                  Global.isAdminTeleUser() ? (<></>) : 
                  (
                    <div className="col-3">
                      <ToolTip title="Pengalihan Kontak">
                        <div onClick={this.props.onHandoverClick} className="btn btn-block btn-primary">
                          <AssignmentReturn />
                        </div>
                      </ToolTip>
                    </div>
                  )
                }
                <div className="col-3">
                  <ToolTip title={this.state.hasUnreadMessageOnly ? "Tampilkan Semua Kontak" : "Tampilkan kontak yang belum dibaca saja"}>
                    <div onClick={this.handleToggleUnreadMessageOnly} className={this.styleToggle()}>
                      <NotificationsActive />
                    </div>
                  </ToolTip>
                </div>
                <div className="col-3">
                  <ToolTip title="Leads Report">
                    <div className="btn btn-primary btn-block" onClick={this.props.onLeadsReportClick}>
                      <Assessment />
                    </div>
                  </ToolTip>
                </div>
            </div>
            <div className="row" style={{marginLeft: 5, marginRight:5, marginTop: 5, marginBottom: 0}}>
              <div className="col-3">
                <ToolTip title="Pencarian">
                  <div className="btn btn-primary btn-block" onClick={this.props.onSearchClick}>
                    <Search />
                  </div>
                </ToolTip>
              </div>
                <div className="col-3">
                <ToolTip title="Kecepatan Response">
                    <div className="btn btn-primary btn-block" onClick={this.props.onResponseTimeClick}>
                      <AccessAlarm />
                    </div>
                  </ToolTip>
                </div>
                <div className="col-3">

                {
                  Global.isCustomerService() || Global.isCustomerServiceSpv() || Global.get().admin ? (
                    <ToolTip title="CS Help">
                      <div className={this.styleCsHelpToggle()} onClick={this.props.onCsHelpButtonClick}>
                        {
                          this.props.csHelpCount <= 0 ? (
                            <VoiceOverOff />
                          ) : (
                            <span>
                              {this.props.csHelpCount}
                            </span>
                          )
                        }
                      </div>
                    </ToolTip>
                  ) : (
                    <ToolTip title="Leads Data pending">
                    <Link style={{textDecoration: "none", color: 'black', width: '100%'}} to="/leads-data-pending">
                        <div className="btn btn-success btn-block" onClick={this.props.onSearchClick}>
                          <Timelapse />
                        </div>
                      </Link>
                    </ToolTip>
                  )
                }

                
                &nbsp;
                </div>
                <div className="col-3">
                  <ToolTip title="Wajib Bayar">
                    <div className="btn btn-primary btn-block" onClick={this.handleWajibBayarMenuClicked}>
                      <Badge badgeContent={this.props.listCustomerWajibBayar.length} color="secondary">
                        <AccountBalanceWalletIcon />
                      </Badge>
                    </div>
                  </ToolTip>
                    <Menu
                        id="popup-infinishedchat"
                        anchorEl={this.state.anchorElWajibBayar}
                        open={this.state.openMenuWajibBayar}
                        onClose={this.handleClosePopupMenuWajibBayar}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >

                        <TableContainer>
                            <Table aria-label="sticky table">
                            <TableHead>
                                {/* <TableRow> */}
                                  <TableCell key="nama" align="left" style={{ minWidth: 100 }} >
                                    <strong>Nama</strong>
                                  </TableCell>
                                  <TableCell key="kategori" align="left" style={{ minWidth: 100 }} >
                                    <strong>Kategori Produk</strong>
                                  </TableCell>
                                  <TableCell key="Kota" align="left" style={{ minWidth: 100 }} >
                                    <strong>Kota</strong>
                                  </TableCell>
                                  <TableCell key="tanggal" align="left" style={{ minWidth: 100 }} >
                                    <strong>Tanggal</strong>
                                  </TableCell>
                                  <TableCell key="action" align="left" style={{ minWidth: 100 }} >
                                    <strong>Action</strong>
                                  </TableCell>
                                {/* </TableRow> */}
                            </TableHead>
                            <TableBody>
                                {this.props.listCustomerWajibBayar.map((row) => {
                                return (
                                    <TableRow>
                                      <TableCell>{row.contact.fromName}</TableCell>
                                      <TableCell>{row.contact.productCategory}</TableCell>
                                      <TableCell>{row.contact.cityName}</TableCell>
                                      <TableCell>{row.contact.deliveryDate !== null && row.deliveryDate !== undefined ? Global.formatDateTimeToStrDateTime24(row.deliveryDate) : ""}</TableCell>
                                      <TableCell>
                                        <div className="btn btn-primary" onClick={() => {this.props.onSelect(row.contact);}}>
                                          Chat
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                            </Table>
                        </TableContainer>
                      </Menu>
                </div>

                {
                  this.props.showButtonCheckPoint ? 
                  (
                    <div className="col-12" style={{marginBottom: 10}}>
                      <ToolTip title="Check Point">
                          <div className="btn btn-warning btn-block" onClick={this.props.assignCheckPoint}>
                            <ScoreboardIcon className="text-danger" /> 
                            &nbsp;
                            <span style={{color: "white", fontWeight: "bold"}}>Check in sekarang juga !!!</span>
                          </div>
                      </ToolTip>
                    </div>
                  ) : 
                  Global.get().checkPoint.isNightShift === true && Global.get().checkPoint.rules.length > 0 ? 
                  (
                    <div className="col-12" style={{marginBottom: 10}}>
                      <ToolTip title="Check Point">
                          <div className="btn btn-success btn-block">
                            <ScoreboardIcon className="text-light" /> 
                            &nbsp;
                            <span style={{color: "white", fontWeight: "bold"}}>Checkpoint Aktif</span>
                          </div>
                      </ToolTip>
                    </div>
                  ) : 
                  (<></>)
                }
                
            </div>

            <div style={{marginLeft: 5, marginRight: 5, marginTop: 0, marginBottom: 8}}>
              
              <Accordion elevation={3}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{color: "#FFFFFF"}} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="bg-primary rounded"
                  onClick={this.handleTogleMenuLainnya}
                >
                  <Typography style={{color: "#FFFFFF"}}>Menu lainnya</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                {/* {
                  Global.get().admin === true ? 
                  ( */}
                    <div className="col-3">
                      <ToolTip title="Pesan belum di respon sales">
                        <div onClick={this.handleSlowResponseMenuClicked} className="btn btn-warning">
                          <Badge badgeContent={this.props.slowResponseMessages.length} color="secondary">
                            <Snooze color="action" />
                          </Badge>
                        </div>
                      </ToolTip>
                      <Menu
                        id="popup-slowresponse"
                        anchorEl={this.state.anchorElSlow}
                        open={this.state.openMenuSlow}
                        onClose={this.handleClosePopupMenuSlow}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        {
                          this.props.slowResponseMessages.map((slow) => {
                            return (
                              <MenuItem
                                key="dashboardTrainingReportMenu"
                                onClick={() => this.props.onSelect(slow.contact)}
                              >
                                
                                    <div className="" style={{width: "300px"}}>
                                      {slow.fromName}<br />
                                      <div className="badge badge-pill badge-secondary">
                                        {slow.assignToUserName}
                                      </div>&nbsp;
                                      <div className="badge badge-pill badge-secondary">
                                        {slow.from}
                                      </div>&nbsp;
                                      {
                                        slow.isMessageReplied === true ? 
                                          (
                                            <div className="badge badge-pill badge-success">
                                              {/* <Chat className="text-success" /> */} sudah direspon
                                            </div>
                                          ) : 
                                          (
                                            <div className="badge badge-pill badge-danger">
                                              {/* <Warning className="text-danger" /> */}
                                              {slow.delta} 
                                            </div>
                                          )
                                      }
                                      {/* {
                                        slow.isMessageReplied === true ? (<Chat className="text-success" />) : (<Warning className="text-danger" />)
                                      } */}
                                    </div>
                                
                              </MenuItem>

                          )
                        })
                      }
                      </Menu>
                  </div>
                  {/* ) : 
                  (<></>)
                } */}

                {
                  Global.isNeedUnfinishedChatReminder(Global.get().user.role) === false ? (<></>) : 
                  (<></>
                  //   <div className="col-3">
                  //     <ToolTip title="Event Reminder">
                  //       <div onClick={this.handleUnfinishedMenuClicked} className="btn btn-danger">
                  //         <Badge badgeContent={this.props.listUnfinishedChat.length} color="secondary">
                  //           {/* <SentimentDissatisfiedRounded color="#ffffff" /> */}
                  //           <MonetizationOnRounded color="#ffffff" />
                  //         </Badge>
                  //       </div>
                  //     </ToolTip>
                  //     <Menu
                  //       id="popup-infinishedchat"
                  //       anchorEl={this.state.anchorElUnfinished}
                  //       open={this.state.openMenuUnfinished}
                  //       onClose={this.handleClosePopupMenuUnfinished}
                  //       transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                  //       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  //     >

                  //       <TableContainer>
                  //           <Table aria-label="sticky table">
                  //           <TableHead>
                  //               {/* <TableRow> */}
                  //                 <TableCell key="nama" align="left" style={{ minWidth: 100 }} >
                  //                   <strong>Nama</strong>
                  //                 </TableCell>
                  //                 <TableCell key="kategori" align="left" style={{ minWidth: 100 }} >
                  //                   <strong>Kategori Produk</strong>
                  //                 </TableCell>
                  //                 <TableCell key="Kota" align="left" style={{ minWidth: 100 }} >
                  //                   <strong>Kota</strong>
                  //                 </TableCell>
                  //                 <TableCell key="tanggal" align="left" style={{ minWidth: 100 }} >
                  //                   <strong>Tanggal</strong>
                  //                 </TableCell>
                  //                 <TableCell key="action" align="left" style={{ minWidth: 100 }} >
                  //                   <strong>Action</strong>
                  //                 </TableCell>
                  //               {/* </TableRow> */}
                  //           </TableHead>
                  //           <TableBody>
                  //               {this.props.listUnfinishedChat.map((row) => {
                  //               return (
                  //                   <TableRow>
                  //                     <TableCell>{row.contact.fromName}</TableCell>
                  //                     <TableCell>{row.contact.productCategory}</TableCell>
                  //                     <TableCell>{row.contact.cityName}</TableCell>
                  //                     <TableCell>{row.contact.deliveryDate !== null && row.deliveryDate !== undefined ? moment(row.deliveryDate).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a") : ""}</TableCell>
                  //                     <TableCell>
                  //                       <div className="btn btn-primary" onClick={() => {this.props.onSelect(row.contact);}}>
                  //                         Chat
                  //                       </div>
                  //                     </TableCell>
                  //                   </TableRow>
                  //               );
                  //               })}
                  //           </TableBody>
                  //           </Table>
                  //       </TableContainer>
                  //     </Menu>
                  // </div>
                  )
                }

                    

                {/* {
                  Global.get().admin | Global.isSalesNew() ? 
                  (
                    <div className="col-3">
                      <ToolTip title="Data pending">
                        <Link style={{textDecoration: "none", color: 'black', width: '100%'}} to="/data-pending">
                          <div className="btn btn-primary btn-block" onClick={this.props.onResponseTimeClick}>
                            <PendingActions />
                          </div>
                        </Link>
                      </ToolTip>
                    </div>
                  ) : 
                  (<></>)
                } */}

                {/* <div className="col-3">
                  <ToolTip title="FAQ">
                    <div className="btn btn-primary btn-block" onClick={this.props.onOpenDashboardFaqClick}>
                      <LiveHelp />
                    </div>
                  </ToolTip>
                </div> */}

                {
                  Global.get().admin | Global.isSalesDataPending() ? 
                  (
                    <div className="col-3">
                      <ToolTip title="Data Cancel">
                        {/* <Link style={{textDecoration: "none", color: 'black', width: '100%'}} to="/data-pending"> */}
                          <div className="btn btn-primary btn-block" onClick={this.props.onDataCancelClick}>
                            <PendingActions />
                          </div>
                        {/* </Link> */}
                      </ToolTip>
                    </div>
                  ) : 
                  (<></>)
                }

                <div className="col-3">
                  <ToolTip title="Closing Rate">
                    <Link style={{textDecoration: "none", color: 'black', width: '100%'}} to="/closing-rate">
                      <div className="btn btn-primary btn-block">
                        <Stars />
                      </div>
                    </Link>
                  </ToolTip>
                </div>

                {
                    Global.get().admin === true ? (
                      <div className="col-3">
                        <ToolTip title="Dashboard Solusi Data Cancel">
                          <Link style={{textDecoration: "none", color: 'black', width: '100%'}} to="/analisis-data-cancel">
                            <div className="btn btn-primary btn-block">
                              <ViewModuleOutlined />
                            </div>
                          </Link>
                        </ToolTip>
                      </div>
                    ) : (<></>)
                  }
  
                  
                </div>
                  <br />
                  <div className="row">
                    {/* {
                      Global.isAdminEpUser() ? (<></>) : 
                      (
                        <div className="col-3">
                          <ToolTip title="Modul Training">
                            <div id="modulTrainingMenu" className="btn btn-block btn-primary" onClick={this.handleModultrainingMenuClicked} 
                              // onClick={this.props.onContactsCancelDashboardClick}
                            >
                              <ViewModuleOutlined className="text-danger"  />
                            </div>
                            
                          </ToolTip>
                          <Menu
                            id="popup-menu-datapending"
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            onClose={this.handleClosePopupMenu}
                            // MenuListProps={{
                            //   'aria-labelledby': 'lock-button',
                            //   role: 'listbox',
                            // }}
                            
                            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                          >
                            {
                              Global.get().admin === true ? 
                              (
                                <MenuItem
                                  key="dashboardCancelMenu"
                                  onClick={this.handleOpenCancelContactDashboard}
                                >
                                  Dashboard Canceled Contact
                                </MenuItem>
                              ) : 
                              (<></>)
                            }
                            {
                              Global.get().admin === true ? 
                              (
                                <MenuItem
                                  key="dashboardSettingModulTrainingMenu"
                                  // onClick={(event) => this.handleMenuItemClick(event)}
                                >
                                  <Link style={{textDecoration: "none", color: 'black', width: '100%'}} to="/setup-module-training">Setting Modul Training</Link>
                                  
                                </MenuItem>
                              ) : 
                              (<></>)
                            }
                            <MenuItem
                              key="dashboardTrainingReportMenu"
                              onClick={(event) => this.handleOpenDashboardSpv(event)}
                            >
                              Training Dashboard
                            </MenuItem>
                          </Menu>
                        </div>
                      )
                    } */}

                  
                  {/* <div className="col-3">
                    <ToolTip title="Chat Nggantung">
                      <div className="btn btn-primary btn-block" onClick={this.handleChatNggantungMenuClicked}>
                        <Badge badgeContent={this.props.listChatNggantung.length} color="secondary">
                          <DryCleaningIcon />
                        </Badge>
                      </div>
                    </ToolTip>
                      <Menu
                          id="popup-chatnggantung"
                          anchorEl={this.state.anchorElChatNggantung}
                          open={this.state.openMenuChatNggantung}
                          onClose={this.handleClosePopupMenuChatNggantung}
                          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >

                          <TableContainer>
                              <Table aria-label="sticky table">
                              <TableHead>
                                    <TableCell key="sales" align="left" style={{ minWidth: 100 }} >
                                      <strong>Sales</strong>
                                    </TableCell>
                                    <TableCell key="label" align="left" style={{ minWidth: 100 }} >
                                      <strong>Label</strong>
                                    </TableCell>
                                    <TableCell key="nama" align="left" style={{ minWidth: 100 }} >
                                      <strong>Nama</strong>
                                    </TableCell>
                                    <TableCell key="tanggal" align="left" style={{ minWidth: 100 }} >
                                      <strong>Last Message</strong>
                                    </TableCell>
                                    <TableCell key="action" align="left" style={{ minWidth: 100 }} >
                                      <strong>Action</strong>
                                    </TableCell>
                              </TableHead>
                              <TableBody>
                                  {this.props.listChatNggantung.map((row) => {
                                  return (
                                      <TableRow>
                                        <TableCell>{row.contact.assignToUserName}</TableCell>
                                        <TableCell>
                                          <div className={Global.getLabelClasses(row.contact.contactLabel)}>
                                            {row.contact.contactLabel}
                                          </div>
                                        </TableCell>
                                        <TableCell>{row.contact.fromName}</TableCell>
                                        <TableCell>{row.contact.lastMessageReceivedAt !== null && row.lastMessageReceivedAt !== undefined ? moment(row.lastMessageReceivedAt).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a") : ""}</TableCell>
                                        <TableCell>
                                          <div className="btn btn-primary" onClick={() => {this.props.onSelect(row.contact);}}>
                                            Chat
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                  );
                                  })}
                              </TableBody>
                              </Table>
                          </TableContainer>
                        </Menu>
                  </div> */}

                  <div className="col-3">
                      <ToolTip title="Inbox sudah dibaca tapi belum di respon">
                        <div onClick={this.handleJustReadMenuClicked} className="btn btn-warning">
                          <Badge badgeContent={this.props.justReadMessages.length} color="secondary">
                            <PhonePausedIcon color="action" />
                          </Badge>
                        </div>
                      </ToolTip>
                      <Menu
                        id="popup-slowresponse"
                        anchorEl={this.state.anchorElJustRead}
                        open={this.state.openMenuJustRead}
                        onClose={this.handleClosePopupMenuJustRead}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        {
                          this.props.justReadMessages.map((slow) => {
                            return (
                              <MenuItem
                                key="dashboardJustReadReportMenu"
                                onClick={() => this.props.onSelect(slow.contact)}
                              >
                                
                                    <div className="" style={{width: "300px"}}>
                                      {slow.fromName}<br />
                                      <div className="badge badge-pill badge-secondary">
                                        {slow.assignToUserName}
                                      </div>&nbsp;
                                      <div className="badge badge-pill badge-secondary">
                                        {Global.maskPhoneNumber(slow.from)}
                                      </div>&nbsp;
                                      {
                                        slow.isMessageReplied === true ? 
                                          (
                                            <div className="badge badge-pill badge-success">
                                              {/* <Chat className="text-success" /> */} sudah direspon
                                            </div>
                                          ) : 
                                          (
                                            <div className="badge badge-pill badge-danger">
                                              {/* <Warning className="text-danger" /> */}
                                              {slow.delta} 
                                            </div>
                                          )
                                      }
                                      {/* {
                                        slow.isMessageReplied === true ? (<Chat className="text-success" />) : (<Warning className="text-danger" />)
                                      } */}
                                    </div>
                                
                              </MenuItem>

                          )
                        })
                      }
                      </Menu>
                  </div>

                  {
                  Global.get().admin ? 
                  (
                    <div className="col-3">
                      <ToolTip title="Riwayat Perpindahan Contact">
                        <Link style={{textDecoration: "none", color: 'black', width: '100%'}} to="/riwayat-perpindahan-contact">
                          <div className="btn btn-primary btn-block" onClick={this.props.onResponseTimeClick}>
                            <ImportContacts />
                          </div>
                        </Link>
                      </ToolTip>
                    </div>
                  ) : 
                  (<></>)
                }

                
					<div className="col-3">
					{
						Global.isCustomerService() || Global.isCustomerServiceSpv() || Global.get().admin ? (
						<ToolTip title="Leads Data pending">
							<Link style={{textDecoration: "none", color: 'black', width: '100%'}} to="/leads-data-pending">
								<div className="btn btn-success btn-block" onClick={this.props.onSearchClick}>
								{/* <DataSaverOn /> */}
								<Timelapse />
								</div>
							</Link>
							</ToolTip>
							
						) : (
							<></>
							// <ToolTip title="CS Help">
							//   <div className={this.styleCsHelpToggle()} onClick={this.props.onCsHelpButtonClick}>
							//     {
							//       this.props.csHelpCount <= 0 ? (
							//         <VoiceOverOff />
							//       ) : (
							//         <span>
							//           {this.props.csHelpCount}
							//         </span>
							//       )
							//     }
							//   </div>
							// </ToolTip>
						)
					}
					</div>
					{
                    Global.get().admin === true ? (
						<div className="col-3">
							<ToolTip title="Dashboard Performa Sales">
								<Link style={{textDecoration: "none", color: 'black', width: '100%'}} to="/dashboard-performa-sales">
									<div className="btn btn-primary btn-block">
										<LegendToggle />
									</div>
								</Link>
							</ToolTip>
						</div>
						) : (<></>)
					}

                  	</div>
                </AccordionDetails>
              </Accordion>
            </div>
            
                

            <div className="container" style={{marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0}}>
                {/* Filter :&nbsp; */}
                <div className={Global.getLabelClasses(this.props.filterLabel === "" ? Global.labelSemuaLabel() : this.props.filterLabel)} style={{cursor:"pointer"}} onClick={this.handleFilterLabelMenuClicked}>
                  {this.props.filterLabel === "" ? Global.labelSemuaLabel() : this.props.filterLabel} <KeyboardArrowDownIcon style={{fontSize: 13}} />
                </div>
                &nbsp;
                <div className={Global.getLabelClasses(this.props.filterLabel === "" ? Global.labelSemuaLabel() : this.props.filterLabel)} style={{cursor:"pointer"}} onClick={this.handleFilterHasilFuMenuClicked}>
                  {this.props.filterHasilFu === "" | this.props.filterHasilFu === undefined ? "Semua Fu" : this.props.filterHasilFu} <KeyboardArrowDownIcon style={{fontSize: 13}} />
                </div>
                &nbsp;
                <div className="text-right"> 
                <span style={{fontSize: "13px", cursor: ""}}>Crossed leads</span>
                  <Switch size="small" checked={this.props.toggleCrossedLeads} onChange={this.props.onToggleCrossedLeadsChange} />
                  {/* <FormControlLabel style={{fontSize: "5px"}} control={<Switch size="small" checked={this.props.toggleCrossedLeads} onChange={this.props.onToggleCrossedLeadsChange} />} label="Crossed Leads">Halo</FormControlLabel> */}
                </div>
                {/* <div className={"btn btn-primary btn-sm"} style={{cursor:"pointer"}} onClick={this.handleFilterHasilFuMenuClicked}>
                  {this.props.filterHasilFu === "" | this.props.filterHasilFu === undefined ? "Crossed Leads" : this.props.filterHasilFu} <KeyboardArrowDownIcon style={{fontSize: 13}} />
                </div> */}
                <Menu
                  id="popup-filterlabel"
                  anchorEl={this.state.anchorElLabel}
                  open={this.state.openMenuLabel}
                  onClose={this.handleClosePopupMenuLabel}
                  
                  transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                > 
                  <MenuItem
                    key={md5(Global.labelSemuaLabel())}
                    onClick={(event) => this.handleChangeFilterLabel("")}
                  >
                    
                    <div className={Global.getLabelClasses(Global.labelSemuaLabel())} style={{paddingTop: "5px", paddingBottom: "5px"}}>
                      {Global.labelSemuaLabel()}
                    </div>
                  </MenuItem>
                {
                  Global.getListLabels().map((label) => {
                    return (
                      <MenuItem
                        key={md5(label)}
                        onClick={(event) => this.handleChangeFilterLabel(label)}
                      >
                        <div className={Global.getLabelClasses(label)} style={{paddingTop: "5px", paddingBottom: "5px"}}>
                          {label}
                        </div>
                      </MenuItem>
                    );
                  })
                }
                  
                </Menu>
                <Menu
                  id="popup-filterhasilfu"
                  anchorEl={this.state.anchorElHasilFu}
                  open={this.state.openMenuHasilFu}
                  onClose={this.handleClosePopupMenuHasilFu}
                  
                  transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                > 
                  <MenuItem
                    key={md5("semua hasil fu")}
                    onClick={(event) => this.handleChangeFilterHasilFu("")}
                  >
                    
                    <div style={{paddingTop: "5px", paddingBottom: "5px"}}>
                      Semua FU
                    </div>
                  </MenuItem>
                {
                  Global.getListHasilFu().map((hasilFu) => {
                    return (
                      <MenuItem
                        key={md5(hasilFu)}
                        onClick={(event) => this.handleChangeFilterHasilFu(hasilFu)}
                      >
                        <div style={{paddingTop: "5px", paddingBottom: "5px"}}>
                          {hasilFu}
                        </div>
                      </MenuItem>
                    );
                  })
                }
                  
                </Menu>
              </div>
            </Paper>
          )
        }

        

        {/* <div> */}

          {/* <InfiniteScroll
            dataLength={this.props.conversationData.length} //This is important field to render the next data
            next={() => {console.log("LOADNEXT");this.props.loadNext();}}
            hasMore={true}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            // below props only if you need pull down functionality
            refreshFunction={() => {console.log("REFRESHING");}}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
            }
          > */}

          {
          this.props.conversationViewMode === "ALL_CONTACTS" ? 
            this.props.conversationData.map((conversation) => {
              
              {
                return this.state.hasUnreadMessageOnly === false ? 
                (
                  <ConversationListItem
                    onSelect={this.props.onSelect}
                    key={conversation._id}
                    data={conversation}
                  />
                ) : 
                (
                  conversation.unreadCounter > 0 ? 
                  (
                    <ConversationListItem
                      onSelect={this.props.onSelect}
                      key={conversation._id}
                      data={conversation}
                    />
                  ) :
                  (
                    <></>
                  ) 
                )
              }

              
              })
          : 
          (<></>)
        }

        {
          this.props.conversationViewMode === "SEARCH_CONTACTS" ? 
            <ConversationSearch 
              onSelect={this.props.onSelect} 
              onCloseSearch={this.props.onSearchClick}
            /> 
            : 
            <></>
        }

        {
          this.props.conversationViewMode === "CS_HELP" ? 
            <CustomerServiceHelp 
              onSelect={this.props.onSelect} 
              onCloseCsHelp={this.props.onCsHelpButtonClick}
              showButtonCheckPoint={this.props.showButtonCheckPoint}
              assignCheckPoint={this.props.assignCheckPoint}
              onJustReadMenuClicked={this.handleJustReadMenuClicked}
              justReadMessages={this.props.justReadMessages}
              onUpdateJustRead={this.props.onUpdateJustRead}
              showSnackBar={this.props.showSnackbar}
              currentConversation={this.props.currentConversation}
            /> 
            : 
            <></>
        }

          
          {/* </InfiniteScroll>

        </div> */}
        
      </div>
    );
  }
}

export default ConversationList;
